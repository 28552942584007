


const productos = {
    'dosquinientos':[
        {
            'name': 'cherry ice',
            'puff': 2500,
            'img': 'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Cherry-Ice_lel13a.png',
            'price': 9800
        },
        {
            'name': 'strawberry ice',
            'puff': 2500,
            'img': 'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Strawberry-Ice_cxv70h.png',
            'price': 9800
            
        },
        {
            'name': 'sour green apple ice',
            'puff': 2500,
            'img': 'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Sour-Green-Apple-Ice_uwynng.png',
            'price': 9800
            
            
        },
        {
            'name': 'pineapple mango',
            'puff': 2500,
            'img': 'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Pineapple-Mango_ocur00.png',
            'price': 9800
        },
        {
            'name': 'skittles',
            'puff': 2500,
            'img': 'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Skittles_juj6jk.png',
            'price': 9800
        },
        {
            'name': 'cool mint',
            'puff': 2500,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Cool-Mint_xc0w7m.png',
            'price': 9800
        },
        {
            'name': 'watermelon candy ice',
            'puff': 2500,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Watermelon-Candy-Ice_hr2zqg.png',
            'price': 9800
        },
        {
            'name': 'strawberry watermelon ice',
            'puff': 2500,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Strawberry-Watermelon-Ice_fjtw3s.png',
            'price': 9800
        },
        {
            'name': 'mango grape ice',
            'puff': 2500,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Mango-Grape-Ice_gucnqs.png',
            'price': 9800
        },
        {
            'name': 'grape ice',
            'puff': 2500,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327866/Grape-Ice_ol3hiv.png',
            'price': 9800
        },
        {
            'name': 'grape bubblegum',
            'puff': 2500,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327865/Grape-Bubblegum_wstwic.png',
            'price': 9800
        },
    ],
    'cuatro':[
        {
            'name': 'cherry ice',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327958/CherryIce4000Puffs_be2sfr.png',
            'price': 12000
        },
        {
            'name': 'strawberry ice',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327957/Strawberry_Ice_4000_Puffs_qrxwr1.png',
            'price': 12000
        },
        {
            'name': 'mango grape ice',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327957/Mango_Grape_Ice_4000_Puffs_oojrkg.png',
            'price': 12000
        },
        {
            'name': 'strawberry watermelon ice',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327958/Strawberry_Watermelon_Ice_4000_Puffs_jkvowy.png',
            'price': 12000
        },
        {
            'name': 'cool mint',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327957/Cool_Mint_4000_Puffs_ytle4k.png',
            'price': 12000
        },
        {
            'name': 'watermelon candy ice',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327959/Watermelon_Candy_Ice_4000_Puffs_cjyxm4.png',
            'price': 12000,
        },
        {
            'name': 'skittles',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327958/Skittles_4000_Puffs_toraa9.png',
            'price': 12000

        },
        {
            'name': 'grape bubblegum',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327957/Grape_Bubblegum_4000_Puffs_n6vmrx.png',
            'price': 12000

        },
        {
            'name': 'grape ice',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327957/Grape_Ice_4000_Puffs_lwjwhp.png',
            'price': 12000

        },
        {
            'name': 'sour green apple ice',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327957/Sour_Green_Apple_Ice_4000_Puffs_umzqml.png',
            'price': 12000
        },
        {
            'name': 'pineapple mango',
            'puff': 4000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327957/Pineapple_Mango_4000_Puffs_dlgn3a.png',
            'price': 12000
        },
    ],
    'seis':[
        {
            'name': 'lemon mint',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327988/Lemon_Mint_6000Puffs_-_Front_Angle_wgzp0u.png',
            'price': 14000
        },
        {
            'name': 'cool mint',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327989/Cool_Mint_6000Puffs_-_Front_Angle_dwkk47.png',
            'price': 14000
        },
        {
            'name': 'strawberry kiwi ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327989/Strawberry_Kiwi_Ice_6000Puffs_-_Front_Angle_dbjn4m.png',
            'price': 14000
        },
        {
            'name': 'cherry ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327974/Cherry_Ice_6000Puffs_-_Front_Angle_jcq9tk.png',
            'price': 14000
        },
        {
            'name': 'strawberry ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327988/Strawberry_Ice_6000Puffs_-_Front_Angle_t9thcp.png',
            'price': 14000
        },
        {
            'name': 'white slush',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327974/White_Slush_6000Puffs_-_Front_Angle_ofz1e6.png',
            'price': 14000
        },
        {
            'name': 'strawberry watermelon ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327989/Strawberry_Watermelon_Ice_6000Puffs_-_Front_Angle_dwmzjh.png',
            'price': 14000
        },
        {
            'name': 'watermelon candy ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327974/Watermelon_Candy_Ice_6000Puffs_-_Front_Angle_dzzeug.png',
            'price': 14000
        },
        {
            'name': 'strawberry kiwi ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327989/Strawberry_Kiwi_Ice_6000Puffs_-_Front_Angle_dbjn4m.png',
            'price': 14000
        },
        {
            'name': 'sour green apple ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327990/Sour_Green_Apple_Ice_6000Puffs_-_Front_Angle_isl2fh.png',
            'price': 14000
        },
        {
            'name': 'grape ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327993/Grape_Ice_6000Puffs_-_Front_Angle_ofskzm.png',
            'price': 14000
        },
        {
            'name': 'blueberry ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327974/Blueberry_Ice_6000Puffs_-_Front_Angle_jmbiva.png',
            'price': 14000
        },
        {
            'name': 'skittles',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327989/Skittles_6000Puffs_-_Front_Angle_cvdqth.png',
            'price': 14000
        },
        {
            'name': 'blue razz ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327973/BlueRazzIce6000Puffs-Front_Angle_pfysb1.png',
            'price': 14000
        },
        {
            'name': 'Green Apple Ice',
            'puff': 6000,
            'img':'https://res.cloudinary.com/dkw8e06fo/image/upload/v1677327990/Sour_Green_Apple_Ice_6000Puffs_-_Front_Angle_isl2fh.png',
            'price': 14000
        },
    ]
}



export default productos;