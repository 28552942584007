import styles from './FormSubtmit.module.css';
import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { formState } from  '../../reducer/actions'

const FormSubmit = ()=>{

    let totalmandar = useSelector( state => state.carrito );
    let tota = useSelector( state => state.total );
    let stateForm = useSelector( state => state.estadoForm );
    const dispatch = useDispatch();

    const [input,setInput] = useState({
        name : "",
        direccion:'',
        localidad:''
    });

    function handleChange(e){
        setInput({
            ...input,
            [e.target.name] : e.target.value
        })
    }

    const click = (e) =>{
        dispatch(formState())
    }
   

    const totalpro = ()=>{
        let res1;
        console.log( tota,  totalmandar)
        let res = '*Hola Smog!*' + ' Mi nombre es ' + input.name + ', soy de ' + ' ' + input.direccion + ' ' +  input.localidad + ', y me interesa comprar los siguientes productos: %0a';
        
        for( let i = 0; i < totalmandar.length; i++){
            res+= totalmandar[i].name.toUpperCase() + ' ' + totalmandar[i].puff + ' Cantidad ' + ' ' + totalmandar[i].cantidad + '%0a';
            if( i + 1 ===  totalmandar.length){
                res1 = res.slice(0, -3);
                res1+= ' %0a';
                res1+= `*TOTAL* = $${tota}`
            }
            /* console.log(res1) */

        }
        /* console.log(res1, ' final') */
        /* LINE PARA CAMBIAR SI SE QUIERE VOLVER PARA ATRAS Y QUE EL CARRO ESTE CARGADO BORRAR FUNCION FORMSTATE */
        /* console.log(res1) */
        dispatch(formState())
         window.open(`https://api.whatsapp.com/send?phone=+5491124042253&text=${res1}`); 
    }

    return(
        
            <>
                <div className={styles.section} onClick={ e => click(e) }/>
                <div className={styles.body} >
                    <label onClick={ e => click(e) } className={styles.x}>X</label>
                    <label className={styles.name} htmlFor="">
                        Ingrese su nombre: 
                        <input name='name'value={input.name} type="text" onChange={(e) => {handleChange(e)}} />
                    </label>
                    <label className={styles.name}>
                        Ingrese una provincia
                        <input name='direccion'value={input.direccion} onChange={(e) => {handleChange(e)}} />
                    </label>
                    <label className={styles.name}>
                        Ingrese una localidad
                        <input name='localidad'value={input.localidad} onChange={(e) => {handleChange(e)}} />
                    </label>
                    {
                        input.name && input.direccion && input.localidad && <button id={styles.button} onClick={(e) => totalpro(e) } className={styles.name}> Enviar Pedido!</button>
                    }
                    
                </div>
                
            </>
    )
}

export default FormSubmit;