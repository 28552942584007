import styles from './CarritoProduct.module.css'
import { useDispatch, useSelector } from 'react-redux';
import React, {useEffect, useState} from "react";
import { mas, menos } from '../../reducer/actions';


const CarritoProduct = ({name, puff, cantidad, img, price}) =>{

    const dispatch = useDispatch();
    
    
    
    const mass = e =>{
        e.preventDefault();
        console.log(e.target)
        dispatch( mas(e.target) )
    }

    const menoos = e =>{
        e.preventDefault();
        console.log(e.target)
        dispatch( menos(e.target) )
    }

    useEffect( ()=>{
    },[dispatch]);

    return(
        <div className={styles.carr}>
            <img className={styles.im} src={img} alt=""  />
            <div className={styles.divcarrito}>
                <p className={styles.nam}>{name}</p>
                <p className={styles.puf}>{puff} puffs</p>
                <p className={styles.cantidad}>
                    <button name={name} value={puff} onClick={ e => menoos(e)} className={styles.span}>-</button> { cantidad } 
                    <button className={styles.span} name={name} onClick={ e => mass(e) } value={puff}>+</button> 
                </p>
                <p className={styles.price}> ${price}</p>
            </div>
        </div>
    )
};

export default CarritoProduct;