import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import styles from './Shop.module.css'
import NavBar from "../NavBar/NavBar";
import Footer from '../Footer/Footer'
import React, { useState, useEffect } from "react";
import { dos } from '../../reducer/actions';


const Shop = ()=>{
  
  const dispatch = useDispatch();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


    const click = e =>{
      console.log(e.target)
      dispatch(dos(e.target.name))
      console.log(e.target.name)
    }


    return(
       <> 
       <NavBar/>
       
        <div className={styles.body}>
           <h2 className={styles.title}>Tienda</h2>
            <div className={styles.container}>
              <div className={styles.f} >  
              <Link  onClick={ e =>{ click(e) }} className={styles.link} to={'/market'} >
              <img name={'dos'} className={styles.ia} height='300vw' src="./img/GuilleWEBPAGE/2500PUFFS/drive-download-20230209T193055Z-001/CherryIce.png" alt=""/>
              </Link>
              <h4 className={styles.puffs}><Link className={styles.link} to={'/market/dos'}>2500 Puffs </Link></h4>
              </div> 
              <div className={styles.f}>
              <Link className={styles.link} onClick={ e =>{ click(e) }} to={'/market'}>
                <img className={styles.i} name={'cuatro'}  height='300vw' src="./img/GuilleWEBPAGE/4000PUFFS/drive-download-20230209T192412Z-001/CherryIce4000Puffs.png" alt=""/>
              </Link>
              <h4 className={styles.puffs}><Link className={styles.link} to={'/market'}>4000 Puffs</Link></h4>
              </div>
              <div className={styles.f}>
              <Link className={styles.link} onClick={ e =>{ click(e) }} to={'/market'}>
                <img className={styles.i} name={'seis'}  height='300vw' src="./img/GuilleWEBPAGE/6000PUFFS/drive-download-20230209T231417Z-001/BlueRazzIce6000Puffs-Front Angle.png" alt=""/>
              </Link>
              <h4 className={styles.puffs}><Link className={styles.link} to={'/market'}>6000 Puffs</Link>
              </h4>
              </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Shop;