import styles from '../NavBar/NavBar.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Carrito from '../Carrito/Carrito';
import { carritostate } from '../../reducer/actions';
import Dropdown from 'react-bootstrap/Dropdown';

function NavBar() {

  const dispatch = useDispatch();
  const [input, setInput] = useState(false); 
  let car = useSelector( state => state.num );
  let estatecarrito = useSelector( state => state.estadocarrito );

  /* console.log(window.innerWidth , window.innerHeight) */
  let num = car;

  const click = e => {
    e.preventDefault();
    dispatch(carritostate())
  }

  useEffect( ()=>{
        
  },[car]);
  
  console.log(estatecarrito)
  return (
    <>
      { window.innerWidth <= 540 || window.innerHeight <= 500 ? 
      <div> 
          {
              num === 0 ? <img className={styles.carrito} onClick={ e => click(e) } src="./fotosyplacas/carrito-de-compras.png" alt="y bue"/> : <img className={styles.carrito} src="./fotosyplacas/carrito-de-compras.png" alt="y bue" onClick={ e => click(e) }/>
            } 
            <label onClick={ e => click(e) } className={styles.labelmobil}>{num}</label>
        <Dropdown className={styles.menudespleglable}>
          <Dropdown.Toggle className={styles.dropdown} variant="success" id="dropdown-basic" >
            &#9776;
          </Dropdown.Toggle>
        <Dropdown.Menu >
            <Dropdown.Item href="#/action-1"><Link className={styles.men} to={'/Home'}>Home</Link></Dropdown.Item>
            <Dropdown.Item href="#/action-2"><Link className={styles.men} to={'/shop'}>Tienda</Link></Dropdown.Item>
           <Dropdown.Item href="#/action-3"><Link className={styles.men} to={'/work'}>Trabaja con nosotros</Link></Dropdown.Item>
         </Dropdown.Menu>
        </Dropdown>
        {
              estatecarrito === true ? <Carrito/> : null
            }
      </div>  
      :
      <div className={styles.navBar}>
        <div className={styles.bot}>
          <a className={styles.button}><Link className={styles.none} to={'/Home'}>Home</Link></a>
          <a className={styles.button}><Link className={styles.none} to={'/shop'}>Tienda</Link></a>
          <a className={styles.button}><Link className={styles.none} to={'/work'}>Trabaja con nosotros</Link></a>
          <div className={styles.l}>
            
            {
              num === 0 ? <img className={styles.carrito} onClick={ e => click(e) } src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678658474/99a842dce79fec63f0cf34902cc0b74a_bdlyny.png" alt="y bue"/> : <img className={styles.carrito} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678658474/99a842dce79fec63f0cf34902cc0b74a_bdlyny.png" alt="y bue" onClick={ e => click(e) }/>
            }
            {
              estatecarrito === true ? <Carrito/> : null
            }
            <label onClick={ e => click(e) } className={styles.label}>{num}</label>
          </div>
        </div>
        <Link className={styles.pe} to={'/home'}><img className={styles.p} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678380278/SMOG_Logo_OK_Mesa_de_trabajo_1_inydxe.png" alt=""  /></Link>
        {/* <h1 className={styles.p}><span className={styles.spa}>.</span> Smog<span className={styles.spa}>.</span>  </h1> */}
        
      </div>  
      }
    </> 
    )
  }
  
  export default NavBar;