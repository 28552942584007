import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styles from "./styleLandingPage.module.css"

const LandingPage = ()=>{

    console.log(styles)
    const [input, setInput] = useState(true); 

    const clic = e =>{
        setInput(false)
    }

    return(
            <div className={styles.background}>
                <div className={styles.link}> 
                    <h3 className={styles.title}>PROHIBIDO PARA MENORES DE EDAD</h3>
                    <p className={styles.title2}>¿ Eres mayor de 21 años ?</p>
                    
                    {
                        input ? <button className={styles.title}><Link className={styles.linkk} to={'/home'}>SI</Link></button> : null  
                    }
                    
                    <button onClick={ e => clic() } className={styles.title}>NO</button>     
                    <p className={styles.pan}>Al entrar en este sitio, usted acepta las condiciones de uso y la política de privacidad.</p>              
                </div>
            </div>
    )
}

export default LandingPage;