import styles from './BeneficiosMobail.module.css';

const BeneficiosMobail = () => {

    return(
        <div className={styles.beneficios}>
            <h2 className={styles.title}>Beneficios del Vapeo</h2>
            <img className={styles.img} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678224318/beneficios/ph5_sncohn.jpg" width='80%' alt="" />
            <img className={styles.img} width='80%' src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678224318/beneficios/ph6_ydldh2.png" alt="" />
            <img className={styles.img} width='80%' src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678224318/beneficios/ph7_dflmf1.png" alt="" />
            <img className={styles.img} width='80%' src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678224318/beneficios/ph8_t3whic.jpg" alt="" />
        </div>
    )
}

export default BeneficiosMobail;