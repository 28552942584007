import styles from './Home.module.css'
import { Link } from "react-router-dom";
import HomeMobil from './HomeMobil';
import React, { useEffect } from 'react';

function Home() {

    
    /* console.log(window.innerWidth , window.innerHeight) */
    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    

    return(
        <>
        { window.innerWidth <= 540 || window.innerHeight <= 500 ? 
        <HomeMobil/>
        :
        <div className={styles.body}>
        <h3 className={styles.title}> SOBRE NOSOTROS </h3>
        <div className={styles.div}>
            <section className={styles.section}>
                <h3 className={styles.title2}>SOMOS &nbsp; <img className={styles.titlespan} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678380278/SMOG_Logo_OK_Mesa_de_trabajo_1_inydxe.png" alt="" /> {/* <span className={styles.titlespan}> Smog</span> */} !</h3>
                <p className={styles.p}>Te invitamos a que <br/>
                    formes parte <br/>
                    de nuestra CREW ☝🏽☝🏽
                </p>
                <p className={styles.p2}>
                    SMOG, FUE ESTABLECIDO EN EL AÑO 2022, <br/>
                    CON LA VISIÓN DE SER EL VAPE E-SHOP CON EL MEJOR SERVICIO DEL MERCADO,<br/>
                    ENFOCADOS EN BRINDAR PRODUCTOS DE CALIDAD Y FIABILIDAD.<br/>
                    NUESTRA IDEA ES BRINDAR A LOS FUMADORES UNA MEJOR ALTERNATIVA,<br/>
                    MÁS SALUDABLE Y CÓMODA.
                </p>
                <h3 className={styles.daremos}>
                    Daremos todo de nosotros <br/>
                    para ello!🔥
                </h3>
                <h3 className={styles.gracias}>
                    GRACIAS DESDE YA!!
                </h3>
            </section>
            <section className={styles.section2}>
                <div className={styles.divimagen1}>
                    <img className={styles.img1} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1679081961/Nikbar_6K_Post_w66oue.png" alt=""  />
                    <img className={styles.img2} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678217249/home/ph2_sbchhr.jpg" alt="" width='45%'/>
                </div>
                <div className={styles.divimagen2}>    
                    <img className={styles.img3} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678217235/home/ph4_hts2hg.jpg" alt="" />
                    <img className={styles.img4} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678217241/home/ph3_okvszz.jpg" alt="" />
                </div>
            </section>
        </div>    
    </div> 
        }
        </>
    )
}

export default Home;