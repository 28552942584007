import styles from './Work.module.css';
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'
import React, { useEffect } from 'react';

const Work = () =>{

    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <>
            <NavBar/>
            <div className={styles.section}>
                <img className={styles.img} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678380278/SMOG_Logo_OK_Mesa_de_trabajo_1_inydxe.png" alt="" />
                <h3 className={styles.title}>TRABAJA CON NOSOTROS</h3>
                <h5 className={styles.title2}>
                    ¿Te gustaria ser reevendedor Smog o<br/>
                    sumar nuestros productos a tu negocio?
                </h5>
                <p>
                    Somos distribuidores y vendedores de toda la línea de vapeo, convecional,<br/>
                    descartable, resistencias, líquidos, accesorios, etc.<br/>
                    Abastecemos a nuestros clientes a lo largo de todo el país
                </p>
                <p>
                    <span className={styles.span}>Podes empezar hoy mismo.</span> <br/>Contactanos a tráves de nuestros canales,<br/>
                    ya sea Instagram, Whatsapp o a nuestro Email <br/>(TODA LA INFO DE CONTACTO
                    SE ENCUENTRA AL PIE DE PAGINA).<br/>
                    Dónde te pediremos información para asesorarte de la mejor manera,<br/>
                    comentarte sobre nuestra modalidad de trabajo, listas de precios y demás!
                </p>
                <h3 className={styles.esperando}>¿QUÉ ESTÁS ESPERANDO?</h3>
            </div>
            <Footer/>
            
        </>    
    )
};

export default Work;