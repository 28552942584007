import styles from './Cards.module.css';
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { carrito } from '../../reducer/actions';
import NavBar from '../NavBar/NavBar';
import BasicExample2 from './Cardsmobil';

function BasicExample({ name, image, puff, price }) {

  const dispatch = useDispatch();
  const num = useSelector( state => state.num )
  const [inputn, setInput] = useState(false);

  useEffect(() => {
    
  },[num])
  
  const carrit = (e)=>{
    e.preventDefault();
    dispatch(carrito(e.target))
    setInput(true)
    time()
  }

  const time = setTimeout( ()=>{
    setInput(false)
  }, 4000 )

  /* console.log(car) */
  return (
    <>
      { window.innerWidth <= 540 || window.innerHeight <= 500 ? 
      <BasicExample2 name={name} image={image} puff={puff} price={price}/> 
      :
      <div className={styles.card}>
      {
        inputn === true ? <h1 className={styles.aviso}>Producto añadido al carrito</h1> : null
      }
      <div className={styles.container}>  
        <img src={image} alt="" className={styles.imagen}/>
        <div className={styles.di}>
          <h2 className={styles.nn}>{name}</h2>
          {/* <p className={styles.puf} >{puff}</p> */}
          <p className={styles.price}>$ {price}</p>
        </div>  
        <button onClick={ e =>{ carrit(e) }} value={name} name={puff} className={styles.buy}> COMPRAR </button>
      </div>  
    </div>
    }
  </>
  );
}

export default BasicExample;
