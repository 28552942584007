import styles from './HomeMobile.module.css'

const HomeMobil = () =>{

    return(
        <div className={styles.body}>
        <h3 className={styles.title}> SOBRE NOSOTROS </h3>
            
                <h3 className={styles.title}>SOMOS &nbsp;  <span className={styles.titlespan}> Smog</span> !</h3>
                <p className={styles.title2}>Te invitamos a que <br/>
                    formes parte <br/>
                    de nuestra CREW ☝🏽☝🏽
                </p>
                <p className={styles.p2}>
                    SMOG, FUE ESTABLECIDO EN EL AÑO 2022, <br/>
                    CON LA VISIÓN DE SER EL VAPE E-SHOP CON EL MEJOR SERVICIO DEL MERCADO,<br/>
                    ENFOCADOS EN BRINDAR PRODUCTOS DE CALIDAD Y FIABILIDAD.<br/>
                    NUESTRA IDEA ES BRINDAR A LOS FUMADORES UNA MEJOR ALTERNATIVA,<br/>
                    MAS SALUDABLE Y COMODA.
                </p>
                <h3 className={styles.daremos}>
                    Daremos todo de nosotros <br/>
                    para ello!🔥
                </h3>
                <h3 className={styles.gracias}>
                    GRACIAS DESDE YA!!
                </h3>
                <img className={styles.logo} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678380278/SMOG_Logo_OK_Mesa_de_trabajo_1_inydxe.png"  alt="" />
    </div> 
    )
}

export default HomeMobil;