import axios from 'axios';



export const carrito = ( payload )=>{
    return{
        type: 'CARRITO',
        payload: payload
    }
};

export const allProducts = ()=>{
    return{
        type: 'ALLPRODUCTS'
    }
};

export const dos = (payload)=>{
    console.log('alguien nos esta llamando', payload)
    return{
        type: 'DOS',
        payload
    }
};

export const mas = payload =>{
    console.log(payload)
    return{
        type: 'MAS',
        payload
    }
}

export const menos = payload => {
    console.log(payload)
    return{
        type: 'MENOS',
        payload
    }
}

export const carritostate = () => {
    return{
        type: 'CARRITOSTATE'
    }
}

export const formState = () => {
    return{
        type: 'FORMSTATE'
    }
}