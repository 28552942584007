import styles from './Beneficios.module.css'
import Footer from '../Footer/Footer'
import BeneficiosMobail from './BeneficiosMobail'

const Beneficios = ()=>{

    console.log(window.innerWidth , window.innerHeight , ' vamos')
    return(
        <>
            {
            window.innerWidth <= 540 || window.innerHeight <= 500 ?
            <BeneficiosMobail/> :
            <div className={styles.section} id='beneficios'>
                <h2 className={styles.title}>Beneficios del Vapeo</h2>
                <div className={styles.beneficios}>
                    <div className={styles.divone}>
                        <div className={styles.one}></div>
                        <div className={styles.two}></div>
                    </div>    
                    <div className={styles.divtwo}>
                        <div className={styles.three}></div>
                        <div className={styles.four}></div>
                    </div>
                </div>

            </div>
            }
        </>
    )
}

export default Beneficios;