import BasicExample from "../Cards/Cards";
import NavBar from "../NavBar/NavBar";
import styles from './Market.module.css';
import {  useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { dos, allProducts } from "../../reducer/actions"; 
import React, {useEffect} from "react";
import Footer from "../Footer/Footer";


const Market = () =>{

    const dispatch = useDispatch();
    let allProduct = useSelector( state => state.two );  
    console.log('esto es market allProduct', allProduct);
    let b = 0;

    const clic = e =>{
        dispatch(dos(e.target.name))
        
      }


    useEffect( ()=>{
        if(!allProductos.length){
            dispatch(allProducts());
            window.scrollTo(0, 0);
        }
    },[dispatch]);

    let allProductos = useSelector( state => state.allProductos );
    
    if( allProduct <=  0 ){
        allProduct = allProductos.dosquinientos 
    } 

    return(
        <>
        <div className={styles.mark}> 
            <NavBar/>
            <div className={styles.div}>    
                <section className={styles.sectionone}>
                    <button onClick={ e =>{ clic(e) }} name={'dos'} className={styles.one}>2500</button>
                    <button onClick={ e =>{ clic(e) }} name={'cuatro'} className={styles.one}>4000</button>
                    <button onClick={ e =>{ clic(e) }} name={'seis'} className={styles.one}>6000</button>
                </section>

                <section className={styles.sectio}>
                    
                    {
                        allProduct && allProduct.map( ele =>{
                            b++
                            return(
                                <BasicExample key={b} name={ele.name} image={ele.img}  puff={ele.puff} price={ele.price}/>
                            )
                        } )
                    }
                </section> 
            </div>            
        </div>
        <Footer/>
        </>
    )
}


export default Market;