import NavBar from "../NavBar/NavBar";
import ControlledCarousel from "../Carousel/Carousel";
import Home from '../Home/Home'
import Beneficios from "../Beneficios/Beneficios";
import { useDispatch, useSelector } from 'react-redux';
import React, {useEffect} from "react";
import { allProducts } from "../../reducer/actions";
import Shop from '../Shop/Shop'
import Footer from "../Footer/Footer";
import styles from './Home1.module.css'


const Home1 = ()=>{


    const dispatch = useDispatch();
    let allProduct = useSelector( state => state.allProductos );

    useEffect( ()=>{
        if(!allProduct.length){
            dispatch(allProducts());
        }
    },[dispatch]);

    console.log('esto es home1 allProduct', allProduct);

    return(
        <>
            <NavBar/>
            <ControlledCarousel/>
            <Home/>
            <Beneficios/> 
            <Footer/>
            
            <a href='https://api.whatsapp.com/send?phone=+5491124042253&text=*Hola%20Smog!*%20Me%20comunico%20para%20consultarles%20sobre....'><img class='b' src='./fotosyplacas/whatsapp.png' alt='p'></img></a>
        </>    
    )
}

export default Home1;