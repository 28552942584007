/* import { carrito, dos } from './actions' */
import productos from '../products'



const initialState = {
    carrito : [],
    allProductos: {},
    two: [],
    num: 0,
    estadocarrito: false,
    estadoForm: false,
    total: 0
}

function rootReducer(state = initialState, action ){
    switch ( action.type ){
    
        /* case 'CARRITO': */

        case 'ALLPRODUCTS':
            console.log('seguimos camino')
            return{
                ...state,
                allProductos: productos  
            }
            
        case 'DOS':
           console.log('llegamos al reducer ', action.payload)
           let a = []
           if(action.payload === 'dos'){
            a = state.allProductos.dosquinientos
            console.log(a)
           }else if( action.payload === 'cuatro' ){
            a = state.allProductos.cuatro;
           }else if( action.payload === 'seis' ){
            a = state.allProductos.seis;
           }
           return{
                ...state,
                two: [...a] 
           }

        case 'CARRITO':
            console.log('estamos en carrito reduce ', action.payload, action.payload.value, action.payload.name)
            /* console.log(action.payload.name, state.two[0].puff  ); */
            let c = state.two.find( e=> e.name === action.payload.value && e.puff === parseInt(action.payload.name, 10));
            let pri;
            c ? pri = c.price
            : c = state.allProductos.dosquinientos.find( e=> e.name === action.payload.value && e.puff === parseInt(action.payload.name, 10));
            pri = c.price
            let b = state.carrito.length && state.carrito.find(ele => ele.name === action.payload.value && ele.puff === parseInt(action.payload.name, 10));  
            if(b){
                b.cantidad++;
                
            } else{
                c.cantidad = 1;
                state.carrito.push(c);
            } 
            
            state.num++;
            console.log(state.num);
            console.log(state.carrito);
            console.log(parseInt(state.total),' pri')
            return{
            ...state, 
            total:  state.total + parseInt(pri)
           }   

           
           case 'MAS':
           console.log(action.payload)
           let p = state.carrito.find( e => e.name === action.payload.name && e.puff === parseInt(action.payload.value, 10));
           p.cantidad++
           console.log(p)
           state.num++
           return{
            ...state,
            total : state.total + p.price
           }

           case 'MENOS':
            let pe = state.carrito.find( e => e.name === action.payload.name && e.puff === parseInt(action.payload.value, 10));
            console.log(pe)
            pe.cantidad--
            state.num--
            
            if(pe.cantidad === 0){
                let pru = state.carrito.filter( element => element !=  pe)
                return{
                    ...state,
                    carrito:[...pru],
                    total : state.total - parseInt(pe.price)
                }}else {
                    return{
                        ...state,
                        total : state.total - parseInt(pe.price)
                    }
                }
            

            case 'CARRITOSTATE':
                state.estadocarrito = state.estadocarrito === true ? false : true
                return{
                    ...state
                }
            case 'FORMSTATE':
                state.estadoForm = state.estadoForm === true ? false : true
                return{
                    ...state
                }

        default:
            return state;
    
    }
    
}

export default rootReducer;




/* ----------------------------------------- */


/* const productos = {
    'dosquinientos':[
        {
            'name': 'cherry ice',
            'puff': 2500,
            'img': 'https://lh3.googleusercontent.com/tvagAUjtb0gJW5Mht5OVLop_i5mPQMjY4PQEp7tCvzK94AEsBwarJB96z3b1jJBU_pm1jSjtfgj8_gSvGghDivdBlc4fhHo_stUVkG8zAvG5-C1CHRhr31LKNBmvZzv35OeABzNSY3fKAndDiRmmiPZkWO11Z7egGccJi8Ey91ZEWZZsW82GkOiRqoVscoYgJKbdGdW68YemUb8yUXPtBwn6sva7AbnU5zDAj7YVRNlH2a7t4nyk6vOF3uWKopFCKA7Ry6bWoaqW69NbVb1Hfvb12Fe-IEvF9AAyWcUfzKPOK-T75h-AF5246umYnLMiGW5igwJssFeSI7wQFDBB2ZaG_xvcf6X8rc13CERU3zyau_IbJazGdu1F9kVoJSJx1LUiSMDCQDgcqO_03DulvPQKgigu8uacU1cUU4DqzVR6NRpgY_rA9e8ZLzFUxB2vPROcHeQWKsIiNFcziqLZGwWD8KPlyl4r6gJl4qqEEido7ge0TIrnwb5qSGcpRF6URn5HMkmWNIaNf_9Da_KfziCQjZEwm2wak3qF-tjch3gOqh98wKgwFKJA5uJiKR1N2NefOkfdAKx9UPsMNXcJR09en9bhoQKmSvNoTv3y_pZQ5Y3oQPdvyoV3R0w1hjM7MdrfeIuKcuKfexhX2rYgZWy4LEqx6mBkRYps0iqRvQ60spa9n8Cuzubd0xbCJyT9fePFiFxK8Ek8h2XM6mBLlJRYrYkhgfQOisGcvdWWqiKsZEmBea-3EgLsyX7iOOcBmoo8BPqun-LbH-EgGRV4vdnMNIEiKUSZvwH_8THkVaUUKaFEQdVxaFrcWgq2qNfnj37TD1bRAmymcMyQ2LoCez_HcZJg1G93qmBtz-ndRiNOlo4jUSp0RfZbgPEbyg-LTHdDVjRk-jtFHR7yHA1wq0YPPat2s1g7Phr-wejSBtoJWh4=s695-no?authuser=0'
        },
        {
            'name': 'strawberry ice',
            'puff': 2500,
            'img': 'https://lh3.googleusercontent.com/wmRtTjpyQfwObJork3aa85Uhnf1tWbXqVulnavCk6F6ftjcbuOdAuKQc9XvF3Aq5OCYU-2q2F2OkVv6EGQ6pO88EIAM0jRUpNHjYrxEwOs0vb_B99C9i7ry9F7ym4tV4BGjFyxlWbOZ6POveOphiQDdl9FhhRQG3YmeNmhhRrRNNtAMxMOzMgkeZ5BK9UX7hn7z_0sZWh9yy9tqvovBpDRHbX_7uVEpCeqyoUlz2GRk4Dnv-FktIagYdaffNKoFXoddpJH-mjYBDbVEjXps1oEFJAw-RomD_wK0ToGidfErsqQ_7y_JNxQs_gqpM8eaiPKoylOwk51RK_vG4TS8_yZ8FCBNmYqmUpHDeQPr8nWmbrI9CYlKaJhdkFEJUEazKJcDV-SPJUH0HBvQRge69ECdsxGfJ6pGYG80bfSoGyhlYR9VKG7fmnEVS_KCjhXcfCcFi3mHL_deO7gaoh0GTjdMfySWFU9EKAGOBC_Ws66uH6Bx25MqWMCH-pZ_CVUwwqM6FAR4Lk6Jowygd41iQikmeLIk6r5_EAqzLzoLFGVzM4wyms-mLyMQyJbNSx3f3fxsBRHgDG0NC5Wom8obQlSzDTgDW3KqyoXjaDcVgnYqj-bdVp5cdFgWzhUrPLEYKQKFfOQi97BWb_NbbPwP6BNKcjbxeUKjzoux0U-dWDsAm1f0rV-rEAkSt5YieMbBQnAE5zVyq2fKrQ5PsTZMXylI2Y-lqX3SfLlaTP5gYjWI3Ab5l2udUMQfOHegfQKmn9CPW_99sqe1St91PQHOHqYui5XTRDGzkvOupKg-kfr6E8hp8XSUAF_wlpXl6SOMN5oI3MUmGGm9EK0_NzY7iPzBjNQ4bvJBoGCA5zClKVV_rw6aBYhxiKdeZbx9NHcng67G3AMyFWaJnMhBpyDaPvynx2pJaL8wvD3xtFxOCv8gNZs0=s695-no?authuser=0'
            
        },
        {
            'name': 'sour green apple ice',
            'puff': 2500,
            'img': 'https://lh3.googleusercontent.com/r5kNy-plLadOXpLAXIUObE1VyHv2YjFP1jj547LJVaJQx9nyp5YlH3YlH-Ooegw5FHFMX9PrWiflsXliCsXQZpdl2LHvhy8OMu74NMU0kB6wAg2h86hTMHjVu_uP44LnsRDtGRhdq3WpA2A0ySZYuwX-gabzgf0k3JlAsbCUsD8v9My3vvU7jCFhiGqLwGsiX67F1VMuKrYaVgqUpaLq3qAg0RFTVKe72RLyNOsimJFoxWzTKYjOa1LGvrX7IhXgJCt3BU22kWplHQwWNIm5963ucQr44tjWy7i6GmxAeayp1oeKHXzGyeHB3GTKO4wYN-6IqJQ38_pMd9ZjtiLRnwUgATtNX93CWFdr67do7kd_u9TKhHwXPEYyYGDITJZghoypR9OeaNOEIqkwFmqubAqG0o3mvpa9jB07taYFvtMoD9u8QjNOBwbugKaQmrK00_-P0sPmD6kt1GoVXUL8LocNh8suLeg3_6lwqZJXrmpAeMMeihfW3ZJsMnkYQRtc07LUQU43VJ-3YgYVEiGgiw6W2Hme3s0YYwWyrjZLLJNEPcWiaF9p3cks6VJcbaPOwuvxLl7REiWdtJfHzqkZkOG-Ipz08oQRKZmw5mOxGaiJzC5CQ0m_HSsOqD-b6p72HPqmOu5pGkMOqXeJvVhz3eiE4yifskdLH4_Clf5Tqtnmq3u0Vy2cmR-Dor578ZMG7u64idugqiKphK8TbVQdnOgb7uy-vINvMqfGkQbgLburorDWvXnc6KO7joPGLKN734CJGR1QD0cLzgBlGXsRmFmDizZ51jzK7zyol1WXgnZ3datty-Yez-dbiC1AycQpkVjQ49_UkMXy0IBEtmn9XH2799stuwLzzRx8ZO_hnVwKpgFj5EGuhMhIk5xPdu1cP9-NuIav-Baii_3FSKIJpdwhjkm8pvjbfxRIKt1ot7XxlB4=s695-no?authuser=0'
            
            
        },
        {
            'name': 'pineapple mango',
            'puff': 2500,
            'img': 'https://lh3.googleusercontent.com/-63764lhWmcArgqPqHI1zVeWcObv9L8XI-bjrNAQntYFFvOpXK8WPLJoNTxPkcLGOplqr1JBmzZBE0OhBw4iWFrlD2GzS9-0ldt2rpdonRRK-pnEVUetNvktdZnwYM9bgMhsLuhKc76NmtN6rfK0Y2za4CoU7pYBb1NeUNQNRjYEhD7cxcYDMbYg_FjoCjbAY0QkgHc0fHwjgFsa6kSkokTK-VZ-v02EGKYlpcDYjHPGEZh17rJ5WYazv1Bot3uClmy4AVJoVtWM2vpVlxoG0_hSE-4_eQ2RWEU17_RAJi5nQ0gRwee4ZJsXiwKXMxv8Tnm7Pk9J9Jmw4CIGNgLz0c4ezNCczIHwc2016EWt88RzfxU9kkMcCMGxR0U4ZorqMlmC7GkYdWitIJDsgAScIdzgmkSOFEZLvzwk7aGSwG2UmhFU8lt2Bti2lIDNvxjEvwBwgNlEX47dJlsvTSzbuIAohfxZge2IzXamjtyQcEins66edSvYsEV0_s2YxDQs5SC3AAYooKlxtwDzX1gU_yQ087TOg4_X1Q0ccFavLjASV4-48tF5g3JJlJAPuv9M3K6Qcrlk7UuxF-2PyNUcpPD2vnmdiHrXcvGqpJnVYj_4t623KMHvz_mQ3HfiXw8_HvGePAhnB8z9OevlkizJSyk-PT90lYJnLCsL8l59gQWugrL_7yNYMX0K1KhZLCV0GSrD5liCd46OjjkT6YqF1g7ONDRoMAT5E5tpqzAGsg05N_xMXvOG18aGSQeaIu5cjWMoePiY79U5SSgAkfwZInzmHcLqhweIL73DoT3HaW51qmfoUNnUFY_iJ7WNPbPHMdaKu0QaPUI2K3t4GPneevYuiM5FA7R3O55M8o0MDSywCYCjpnCl7a15ogdXrIF3aGcjQ7dUoB8sDyoVglNxoAZFI2rhZKJ6VPENj0bDPH7pxcY=s695-no?authuser=0'
        },
        {
            'name': 'skittles',
            'puff': 2500,
            'img': 'https://lh3.googleusercontent.com/xkTaiZ92RL5mvrdTDCGjuWIlzIABGwMbwtKpNSu6905ORUCiEmyzkTbDW0DDjlXQdnDTMfjE6OXgNoaeLKoim7oqBHgRMQs84X6Si2onvgcoxaMAprnfLW3x1mCw2mNHRVWJ00c5Xz3zw_bE2Yjg70hk6RRbVLnpVWbYtKQqYFxuX0sOPqIvr_0JfqGh1V2836iDGTyvna6-Co5DVQfEhPuyZUpGfSC3w-K871HH-ddKlWk8_gBzfu-WxC1jrT5_il0o8KMuG9ij3vXduYbiypOnQFfukFM2uYY5fVDwB7i595rd8dUr5dyYevBIERGHjXMyhyCpLGn5QRq7LVHWW6oJFlNGFulDnCTauH-kLH6osJ65x04QXjOoPZqzvAqF146oiiA5vWXbx29nfCqKwfhhr-rM_ySEjgfJy-mjw2lDkKoKJemU8KgYRxTgG_YJZ4XhBGEh_5-4XXNAYrQlqryHxezgiY2jBML4zBgcS-o7CB2eYG7p1HFs8QK9Aoe7dCxLcU-DIQCp2gEbqZZCLBPkKjaNERHIYtQrGQdNXTv3EBaT8AQQYzyWHXCkKEY_PAePps-2RfgQsSS52ZdV1H4caNN2HcyFY1H6JIhQP2dEAVrfUfyrtMMx-moAznx9V9uKbDL1wfHwu1OyS30T2ZVpPkXreD4uso8j2OhQuHUdNUofoaS2ois6CGNK1FuOSO0zE0h2PvMOsDqfgsuN3yPJUE3jvJtLYwHEFLihN3COYAaY4k5DoQS5Oz74daI1Cd6dKZpuVjQwIjkAmv4SxelzeUDWwHRPzhHOhHA_-Z-iqilQcgxrxQQLg92-iMEo4Bmmwl6qE6EwrVpTaKTxVYYwzPhQl8NrlERXLcPa9QEykwjh79dBNTJ2f4BYOWk6yQRMCFqx0m8fKxtSTUvSegR8rOWoBMJc0rtDn15ZKB7hjRY=s695-no?authuser=0',
        },
        {
            'name': 'cool mint',
            'puff': 2500,
            'img':'https://lh3.googleusercontent.com/8drML-nT31YTcnU1GPrMb6DV-X7BKgvHghMIjvg4GQYKaQTB0-w_ieUzSge__YjGrI9GRv8bUVHEW1u4UP5WJcgy8HqvdxQYKUzGvRqPXmWcGwzIRr-K9JgdwI-pKbY9BqRed_LTVOvar3meTctnWVnJLzzg3gdH62j4OP4IzGORoE4LNVoGUVkvcXO5qSxMCUCw5ra50g1DV-MS-Xac9pZXuT6PcnLEQ9lTXephhi_-azIpo5Qm9oiLo0SFGr1Ay1AANDUipygxQX5NMKq_Mv4jd-SAftxUQfr9eNCmu3iNuWm6P3QMzHqy7oULBpXLJ8uh5lgfeNnJ3RuBxeuTW8yJTn3PCRYflKYZT7UQOKXQe_XMbitrZRlYNKPwW18_ltYp_glWALJZdwIY6otLzVmZbHbNQTaX_D8NoVcaIMv0-3uJVY0n_TysqkdNx9io9zBhfg12fTxjDk2zXDj60H9tNdbT_0OcyD3BBhO7Rc_tzdPWgEwIjsJGTCKP1tTCuOFjJqtKZ8HzK6X4ez0r_FeT_eaIazsQ9dF4rJWpySJYwo1aDkFCgZ9BMwsJ43oa6--VgPgTP4g5d8Zu1rMF0XZnD00EKQmhhD_NeJ9Foookf-hxrLyiUAHdlqUEEOVLGUWT3wXbTh-CkvbWMG-_lJ41hVOyfO_NvQ1lgk03iXq5BdeqlL6vYHR9cNfWNLQ-F0YaIVvhia5CJdqIM_qcFqVKhhvR6PpowFPte-hrm_y8LKB6oSDlvg_Iy2_9PADcW-OtPcmMwC5qjSEcDGBcgoRYbE54wd-tHwVopjmGXX-1BEGjwJP2rkMKgcoHAe6mI4wzk5Rn3ckBN-EEeP8SCv6lVZPlODxuqjlTGPKQ2xJiA5v2amWKDI6atV5gVujkZZvacH559OIdMw8W8qKFEjJpsRA8bNPG4q39tEhANPcomTE=s695-no?authuser=0'
        },
        {
            'name': 'watermelon candy ice',
            'puff': 2500,
            'img':'https://lh3.googleusercontent.com/LoqEsFNGM3ySapL_c5AkiaOaxEcQrqL1CI7FGCS2lcEG7MoYqzwLK5cTdEPb_JjReziT7gTqAkut2Ldj7A__fdpW_C8QR-akqa8cwXXMPjRWVeYEfb46RNU-5gQ8Hn2-0axf-jRjdoMP2VCEh0jnV27T0koJ5ArrvbldobV6gkzj6oq2fB84az997J5Jxkqif724PKRHp8hPiuEMUZVz5kputPhvNVNPEbywn-fQGaZs-63gGFVPO5ttEE4srVOdL4inGlMySCqL67ubxqnsVEknKHUe8yyx5v3pr_a_mvdQsze0LrVp4uW0u_vpUIQQAIclbJ4jQfFXhIjmqp85wFHVwK4CF3KVzEnp9fiAH8pDi2vc1KRc2TK3I7yj9ajiZ9r2S9rn57mX1uZw9QxdR7AhDuGxYqnI4gDJhznhRK9K9rqwIT8NI35fF1EbTkbNNqUM_U1LX22evaXXtCJpZITattFWfsVSUxDzpOS7opLthmYhdITrK5XNABfERDQ8PSL5_HKrZgimu9jYNGNgpbPcL9QD_xBKVLoISgIM73exDxgvgicX-UBNRVXjyiPFRVoOS6lruhV6UBDX1LARsf_uFFo9jbBh1PiGx0AjHRziVJfS2Oy_lw4fvdAE2zLL_tVowKFBuRrILrop5pMgb_KpBOGCMf346R8oFstHaK2AQyYST7a0SqgaVZZ2rWDLiK7in-AYHuthuj1Pbn4i9jDIoGR0PrBNsVooBPViXIFfh_1Yfte0GE_605SUTUx2ir6hKF_Y9DWa6JsPoSs0MO4NMUzMBwctT0kvlMGKFHucJNmScEnJiXrbAZ_ETEPar_OJhKoRR68XBrX01jLiOR8u14RxfCvW79ygHjBfp2vCjzYaifvgOUTzIq6Bi6oWYYMuUAXpiFLBnJ4au9MLdJEWqrHCSmtOjPBQfJWzVc139_8=s681-no?authuser=0'
        },
        {
            'name': 'strawberry watermelon ice',
            'puff': 2500,
            'img':'https://lh3.googleusercontent.com/BR7V1NSg63XWoijoE4j1TiVjYFdP1tmZUukm74q90dftQIztcHDz0yjVaDboE5X8soIsF7P090WAKHVwPChvVqiMOYOR2aNA0grZTHLtF_dnvIocEIXH2z1kLDpZpdXZ-yrGn-AMfwMOusW8-dGF4fUlXqnQ3noxVtzcTifJNAyWbM_FKnIuzthYmmuC-CTVf5zkBbx9FFFXrup803Hla7G2KMC_FNtN4DrBgJsfcy5EKVGDp99VMiCuqrQrbWMpSTBp9SqOFCGaArNTgCLWc3p46JQFYsenprZydtX-SaCVmLy4MxzTUZcHImq6fsOs6iV51LZV5BD-x4Ddb3AFiIfc5JeEEA9Vh4iVlD5LFX3KTo4eWQKwplyGBx9apUMf2AbvScwP3iONrbbfcEFW_CwtxPrNc_GNwzg43DdxoeHqXT7hlGVjJxulUVIfvOahs-4DqpqiuLtAWPRe6EZUnn8jo5NoUBm8dHTCqqCplqwYhaIflH1249foIHaxYIJCsAvA6LuIjsmnj9E5UHTM059e46U5DA3tfwW6TUFXRvrfwuKvbBIXRDQy_whVl9gkTLjrGKmB8VsZ8Bm-6Yk-xYuvYkiRnfbEQkn555cTU1zC_hcsZ6kgeA6dtpcbPVpNO2N89jHnoCDa-PDINEDubmLLyKS_coPcZsEtQwUYuwStWwkPynPgHB7r5szEv9jMVdqg-bSfqABDETU69VlvsHC0DipyXLe8jnehWP1HwjeKG_Rno2NBT3uw3ksfH2F4XjFpzqYV55Dv05ZPpdR_ho3_Su_pYPnSUis-Ekw2Vo5DGlaNB45n9Zmel0LKCAlWEyophqzqaserE8FmOoB6aAIRzj56c-nTzzgPcacBhPMMsLyr62b2jJ5vbdT9zRUONDGwnT0Q9CNXyOa0C6sVtYEYBsfwgyHR3RYyWI1N_nqmgN8=s681-no?authuser=0'
        },
        {
            'name': 'mango grape ice',
            'puff': 2500,
            'img':'https://lh3.googleusercontent.com/m1z6mAYA1Pxft6_sWLX5HOnz1b0w7KkI4yv_kwwQxW-k4EsPrHkt2E_O4QDCgjLwVZqgOmqB_JzhbO5IL7PTfztJA04WQMjH9DcqMYXFl5MjfTSuH9dI_NHuz7qLCy1f4_mZmJ50xN5EkDlikahD5sCC3XmoszI7OsU3qzUut8IaImhDDpBd-nTcxc0VMZtSf0B5-rONzseUFY83U8L9ppJsWiTrrcblZmw4d_R-dT88T24ZH3nk9tM71-nxTNBCy2Z5_QSZ8P-0JFEW69MN5fYBOisBFOP_7DcurCW4l4HONSXcpu1mdHkv4yhmwVdNBLO_QlDv_EtDQQ9WELZXBzpEfx5LH8evENYniAQ-62LcAhuxjbrep-2BSzQny2sazFl9Fi7fncfpePEwlg1kFE3QNTd5gGsQM-_c-307o7L96cLOZd9FcyVkxL0KKRBj9qPq_qZwbUI0NHTrYLCM1WRodVAZnGNFIKN-WA_WeuLHgN6d7ZuQ3OmWlweO0r8VVM7Qm74O0j3jasaJLEKgFdLOqNac7NGXfhnlAxcQ8vjOLgI6VFBhNWq7Qf8bAKhX3ggVc9Sb00GpCI8-ih_ZWqMMzocwm_BwupRJUgE4-4BEe5_tf5lpMalr4Yu5D-JLbIb3XLBZ-HDXvD9Ld2did6YSivqEa_O5r4XA-zhrw3ug0rSA3QjRGR3t8czt1JJBu_walhI9C8qnSOWB7si2fpbXft3fFJZW2kh7Gkr1zDs1H-p7QpuHYX0kqmElR7xNHbHgd_yF4wyYRk6pjGfl48DfOAIxx2lIPilM9BztERTLL_B6FfCXmIGUKNTLB9Zuh0HF5LP7UZDyt9MeZoFNBv2VGmlP4gpi7RmHulCggHSDHsCCa6XssQYiPsjj7D-atEadid1pMM2Hk6IBRYQFpKvFMmjGmsV6K7sIOfAbDMgQB54=s681-no?authuser=0'
        },
        {
            'name': 'grape ice',
            'puff': 2500,
            'img':'https://lh3.googleusercontent.com/1kPK1ewgnSKLXHh6Auhbo1QHC_MGcvQL7BhLCqDj9EfHxCYDcgpZAsZ_fxEt0lFLWYmLgMmaL933qkZrpjtL8LflEHZNy7lbOeSwl9ND_sc93gMyPtixW3OM9dRdaoRiKYoHzlUMyRUglPQ84k_gmfbQy8GR69MvcirtYGSZiuHckxoxAV497mRr8Z_OFWHE6dFPOSETwkDvxTYBFvLtJ1vhVj2g5RB7_1Lgy5YChLdINlmw4-W8-LQbjKbV6-Y21xk3jtglt9gK0xhH7mRDy7QPpisMCMl4_KNdZpf4TrQY7knFXqOFgjfl997UlH67XQec5S3DkyoAwH7cfanny-37iuet8PgXjOKg0Yjie_OhQjExqTt-26ttlutbBmAr17B6gEI7U0-wu-4jcNhL3SJiIsyRtZL_K-8UgkKR3bu0hvs234Vav3SK68KM72Tv3rBbyP0bgJ5cDTtpdEhzRMr0fu_ndxZTTAHgFgqwo_QEbwsxC-7nUjpLDBicf7PYzMOhM-pqRdWp0RIRYqETRrfMGhe1g_yJ_uFH9saNOSE9EqMs0zGEonFllcyHr3N4-51Dlhd0npdmZEeZf_WxVYplG5Qx1uxOSi3bTGYZbUhaHH-KOp4vWv4-Su6jTSm-uY7oCpLf3eUOmO3-uBbuMnNsVij9Dmb2dpS9vpZdnf6SHZdCAuxMmsCBYQQNUkvdjT476xkBU8mq0jQyNoYtONZuK6JYpytk834gMCNqmlbEUj41fFSh_03vMZ4K5b3-Q_oWdbCQ81yIW1crc7kXzzHAJKHDgaIYlrclgB2SpwoKZ-UpmVpLyrhH1IYHQ7YrF6_LH0y3UxINnTqtdq2AiZLc5EoHsOlTs8nVXe5dS7sdFaB-YFzCwVWGFIyKuSpF2hLvjP9craD0Ooqb1_1k9c751aU6yAlwqGcAoq1OoSIBjmo=s681-no?authuser=0'
        },
        {
            'name': 'grape bubblegum',
            'puff': 2500,
            'img':'https://lh3.googleusercontent.com/x7Yb3xIGfOxK1Q6fHmStw0q-D2t7GuUW7iWG3acS7RHkZOznrSz-zlKT5MggHoK7tnU2F_u4F-f5um2Vcr8hsbsp0ZGV-BSD8K03PQHMTcDHkYOhOL6Fm4_hIRtsC8jmwUOlPcnLOjl5SuM7jeWGPY35qhjQi-Vwk9UlvrveQZIx_IOW9_Ksnzu4ZhS7vntoJ5wb9hy_T97zhHrwpBj_BKzhBRLv6b-Ca0EHyOJjAXJRj1Ivyeq2tI-qir2hhqN_v7IHCbIOBQ7q8KHK9Vuqv6-D4KDoGhNUvRimK0OeCx0iuhvUiJUzDZIhZ78627Wu0X_PiOXcpVcfe3xQ9RawH6BARgd0MQBOfofDd0p0n3n8P09ct1TdS9jZeh0cmyLElsYszMNSen33CINI0HvjOfS4p_lKSBsrY6lSSpQMuC73jX6iwRenpcEqf1auCv4EKs5HI8tA2xh8lbDCzAPuPl1Q_QfnFwIu3IZIGsH89UtBfmyGImUQCnBa2WN8rpUCdT6ztXAIkuxIZ9ovbfgDGDpM42kdnRlBN0jfF5PkDvTHGiF8vJ_lzDKtDsDlw7oDTL9XTj3CBZtYItJ1cWWYMmk26hYxNrHxQ6Dlhx3Z14BfV12-mdI-bdGV6dJJMZgsgamCaLx7A9bFOmIahXbcabApxo-CmxVSeK2WedMGjShJDz9Jy_qcDqkcjxbfr-c9dTdMATlj5DAU48MCvcfTCLT3iESG2E9XhbaYYLeCedevuMwjXefBtTFhnMqFXXrEdCbLXbhb_A-eJWaEdAyKOKSXGH7ORlcJ4nXgO2pbaB___v1lc94p2JEmBn2P04Hf5SGSMdy3kN3zvl3V4Jp8gkgEn_lLRSBFKtYf-JGSAGZfelUrMa27A1W-6f_wSUTPBgFFopZFagWmbteFEY8es6_MoifrG-RGOaLYyUOaZVBbRBs=s681-no?authuser=0'
        },
    ],
    'cuatro':[
        {
            'name': 'cherry ice',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/QbnC2QyOIgWRmpF3xOF5oKAV57enslPVRKuppCXv-alqJHaMANAP4dQCZ5lLjWxE8ERVFgLNfhzed_aT3ZDrDg397MMRl38FiXEVrCeHLKvb4VG8RdVSPqF2HEUOHOfWyMgNAC8oUA2fyqGgzko1VgQomH-3BmztPkbZRhh0xv3WoSJk1H1P5D-cF64oJS3tUl9WyUdYIvLSF1zRdZ1EYjasnVy9rGzGEbFlA6d-Ux_KwA3yRJgfI630FkJEa3gm7hofyomSPLjeFHUD0dX_PqwzSLOffUXgtaRoeFjiw-JIiJK-l9rpQvf5BoHkdonAxSDz1HH5tqvtWDJ6S9zlikOtdxArLKulWQT_RMIsVabPhO7lzZzM-N6MKZjN1fRfuAOEKwZw-ueMkEhSYBm1PpSQqab67b6OQOlzy99Jqq53suLjzB3pBxtKbER4xktVTAbj07VfnzvdRiC_kTnOPAu1nMlkLx6A8oKYy3PvWeexa7-BuBwCQ57tItWm0o_vXe52Fe_23rFl-NgAkPYiDxtNhYL7E7JB8KgnzxCkqsNaO2xFb-73VuCNRW38HN2ith3jKQ4CTmINnNeeZ9uOCnZhoJitCXGMUAEHdCyCzQuDdq0BmUnKDXd0rXdjgpP1V9PFtC0zBbJpVvQaezdmVc_C3nJwu6nXJLwGfOHPCCI6OwlQtZngyZVzbcF0I2s8FOPUxaDDE7CIb_lJcTJVMNorQ4v7CBLXMTdkq2E9O4CKMICVERLuVi5SUunntEJMDcDCFkSqBQcBDJ_7grulR0DJI2odDP6S_ibKcy97SW4eTSHm_urDbzh-CQS5lsBv6X98_dXPSMri0S9ytsE_sHxvgboEqdUGZpBsGIA2Sy7LFmwrRXBfO1fT1S0RmBgA6IKBsoqm-KN1EB8vnhH3wYTP41SByQ-uHddywxgDnSbgl20=s681-no?authuser=0'
        },
        {
            'name': 'strawberry ice',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/mRJc3d_Sciq5O6qXoFZQDke_cAKIY964_ht98qRASE6abWIwdAJAIoRnvv0JB1lC-jUyIjN3LlZsPDCS6Cg7eeEpguZioBY7dcRWCffUryhrJwTm0HgujCDYNYENAxSB5ftvaZgihNayjSXu1QGvl3KJQBeRbqgJv2crtDkEbRXjegGrFIonWWa09_9MwYhOVrivIy-Fn2E4zS_ULSXOpqC0_KQBdfjEdz-gfKk7-aNHBnLcgJSKTmJr7vka_0XMg-x4XBFh8fLV0CyZhzTx2nWMcEzjhPxm4GfYwKpnRhWu6-vb_4Bf3u2kz0uZ4Lq9aAj6rxvfPeYmg6cUD7v2qwz8LYsyQpMRg3aOvY2WmCtTpikpJ-rjA69H3Bl5itF4TkSYdKRB3VSusjgGcxbAKA59QAcBKs4b91aBddYd2fvftAC1VJq1AUYmsD3SVE0PDr0J8bL6tCJ1COrSxcUx3vTifLhqZi7QlB4mB2iSyi1_XP4xHvo32vz3yr46kq2eakZfKHorXo4YqZAK6Eb3U66XkWT54-mzt_izffgxVL5QQh7EEZoyd8caIAIg06l3d8U9EF651l9cmoE7JpkGeUCMaCjJ5x-VyAsdfoYK_FqoGGlF6gkYzm3iuCNvL-nJgdfFA5EOJWmP8E3ck6t0Ta1hj0iM7NHIcwIB4Dl_gNBhvrGVJNyZEwgR1RB1dP-qEiGlNxOmMQtn1bN7utVXNaCgCFM_U16VROfQzHffjCeAJsRz8D-EjJ7zyo6vsfoUqKTP1n_ZDvHlnr0MnvSMOWcrQCM8kbkdDLt09o6s8KSliazuwgdWAxYdkAIMLr-pNY1qvM9ALjgnawCwDoilnXPXGmlEHHAek0Sq_35KT2XasaJ-JvUe9HxaUDtXJexaFIUMQpzqp2nbzweBb6AEP913G7ccfzf6GxT1k2Cfh7u9vaA=s681-no?authuser=0'
        },
        {
            'name': 'mango grape ice',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/4avVV1jwwD3-ZG6yyn4eB40UWkT61FUBu_7NMUaCSYdTrbVmvzmm0x87gUp6ZzgRzub7MFbPMxMF2GcsP2GVcbQmewszLw-4TPL9zAv8zbuuy2wBwddSa58LKmXl0PZKoLfoBRonKCJiCFRl2kGJxuTnrOOwMQVdcYz1KU8Qqb5lNbE7AF0G-9nOpuSIe1EHa07jWqoZArBtrvaBBQFCIr4n1iYOGeB9BhCPjmmQaJcaNIggi9R7Pd2W7qJM6BjuH_dpInZj4kNlL_rnYwsDKYX_imThmld9hJRaYjmsxySsJGa-gHXA9rOBpU7ViN6yM0Mi6mF0FIim6oPIZqjqpxaXWCFUatfCHAhwV0hHuttyxi5S1L--IM4Yx8y-Vh4x1Mmp0SKO2Vf_B-rtlJscp4pqwdOi0MtusRqR91M5UFPznye_lVDnQSSpimEtbOTnB7ZqC6t93xtxG4vtqV0PDslTOciHrDW8AQt0aq9e7xmqrzCuaa2D0pv5lHwT-BOpbIru5m62sKMH6I3ncTaE-ecXB4_yrMOEGvdh0DlHRAQv7fqqHxUHpHRkzapPqCm0GGhWle6APe3L7xCwGhaaGZJp2EhH2ryr-D1f7Wq5OI1MkPCEJ9ZwvQkAGvfKwHfm4z3GnyY9gDIGwJgaREHQkuvczYoKliS2ONrj0kPvkPxP5DLJ1a3TxUSjL0J7vX3IWBDswI4C_senHbMQFjx0fQb1NlNOw2-LDfZobmI2izZJy__ZcFKoOT90YVe8UMfmR5YRaFh4a6mgdYv_G9fkA4jvtkdF5ICBs-w-w0gRumYUXYgoFCqaXIrJC8nslg5IKCpcdIzp5_0dRUnUVovdWCnJ2MLNWKbm7lBx108wt-Lb2qWKeHSW1KVIWIyjjMgLgRF1wYE-G-wt6dtzlAt8qLpnKQouUiTLlZFbIl9E5lMirIY=s681-no?authuser=0'
        },
        {
            'name': 'strawberry watermelon ice',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/SQ498LltCf4Z5lG-ktULi7P4AMpbSBNq-j-eRyxfWoh9hCeSSh0YnJuK3OUTRAyGhMiuVqh60cgX68vGV56ZgXVF_L_In3JYKctHjLPkkxIrIiawdg4-5kiK9G57G8H4sOW6I7zktnLT3UGlhVlyrVtdontJLja8ysoMAozgDDvxbxko9UtgwoNmC_eg7RqKUCOf6ZLqidDMLtfaNMWFhWOH3ReysGY8jz4u7Tbm3R0F_cb5qOx-_8JVAP8SQcbOgxCMcLC6D6P1CGT90VKRBaUTy9A8gpTS9vLJ4PvnRbTOMCX0gxZrXbCVNlSXCjWISRbTa9mfvQqSJJ2VXmwH2GjocK1oBSkUFe34Dn-D4QnZPxn7iIxjglv1LPnGpCSnqkMSI1OYUPQ__h10xvq4N-v0PYG27IlMZoqksnpAiqiDUo8uHMj0qXvRkKH8o3foSRPe1HiX6wNO8n9SuAThwypdgpcu-AkS1oW5Zl-L4zZT24Do_i8jdV3fpNvJoSXCNGYivpzlrk9rmGQ1exLtC0t3Vq5h4qPOkcOO0u7yNCGcV-pGDiUbrLqxniNdeWcHjoXNSCQOU6zWW-IAiapNKjyHKKrYb-kEi_UZTqVRxFM45makbrq3pHfZRo-ayiUeoM7B5g0Jh2fOKc1DncaH4PHbnzUTnhijyufrUV0XX4WHSYhqjukog9Vy03r-ezHA6ocqtMj3SAI-FHmwwvK6W3muQ_CACfUHQNdbpLr-67dv8wL2J34SH4_Xa0il10-rLUuMlEMu9iu0Zq1IeGEiMdXXN6Q0xlE1i7kO2-Ztd2aU9C2zY-YfERG7B9hk3WJWsVx7PEpc8bDxi8XZBwZj9bBdurdLUjKnrlwALD-ZUqur9RlsslEoAp56TZibJbipIEHsr01boaWduf2P5ib_LFnHRhXvPhcl-V0-MYWTfmBj-Ag=s681-no?authuser=0'
        },
        {
            'name': 'cool mint',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/52OWULhBED1NhDVaVJtzZsiVCy93nYAdLgiB3O8hJK2yjE7s-PgwZuKdqrBBXso7iH4G3u9g2aB7CGwMSIIczanxGnhb90goRFUOESnpKUHxWYMAYBcvDoqfWC6IiPqN6NUXCauS_zPbLfTS2RX9LuP2qBByloDcmvO--95DhVNKdQMj8JUbzKX1nKMf0vJghPrmKEZLhcZ1tDJzehFqZGBZZplPlyN1KOVW0QJTMIWfLFngVbV2rMAJU1MOzFEzBtdl2Wtu823lUQjbsAeKVXMfBJrIXRqLpIwWkznWNn2qEFZyUXYIxmc6qPt9uA9KatCIuvEcQh9iitRAmEbWp5GGxZqXxx9KgsjgN5Yp0hcgAyo-O7qqSBtol45l-0g4Vy7rS4naNjK2Js6whXM0Jad023XxMZyZA2SGUGXf2GaRyCy76Cbj_P2ht13KWOEeJ03A3dOSrp39HYcXHtGK0zMtnBtsgUTeQNCAa2_CKmBh81XsJOLJnU2W3ykkep8LEhgPz0ac4QJZPi6lzW9Myh34I5DwXspXVjWKu8W4W3d2TIKZb9njI4GMmaFiaHTew_ixF329C0JGDbykKjjbRjgtnwjUzINvx6UsKuTVA16Wj3ze7swRR06Au1VgMtIfh3qoizXpj7NfuKE_3zMF8WtgqUZrpEjNChIple-otPecAmUZpmY8HLSGqt92zSfYoJ8cdNIKiZkoy4dcewlgmmmFdhYaELJy9AytFuzHfv23Fk-qJ15d9OrpqeXyW1nCehx2jDqcodl2sBGa0RF2-oYpMivaYTF9WbLDcmmlDN8htI1wz2cqH4JhQRdIhLQu7L5oU0KgwVhReaxNqr_bueRkSvF5oP75z9oAtSUSL52GU0rMXdWbcsDFiKkAWIiR2wo6tT84CRKnndZJVrus6KskTJNbbNd4mu51MaYOUATVo0E=s681-no?authuser=0'
        },
        {
            'name': 'watermelon candy ice',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/8Ze6eZCBs8EhLy83Efwp9Ffc0cYM_Z0lDcELYkVmCWoAxxcXGGe4BMZhgjjYZC_SeH7lOz4qc7t8E5INkSDFC4T7rIQ5Qp2Kyj-J9JIhFsuBGq-75hJHGMpM2E47_V7jfK8UFyjQydm074A_q4rgZ59BdtUDB-aBFUsSTZlzAHCJcpkMtAMdpwG5v5j0qxZJGvYLsv2w-87NCsoii6LJJZKLGHiMHu3f4twQu1zKIYVhUSXpQ0rtUT-vfY5lcFuOQNBGENNgIV20D1P_0kmNJRLtzp8gXaKVs8A0RV1VvkjdGLkFIvq3s_ZZpjQqRO5PpUyFxOpsKTrviS4cnIbeHi6pTVaUWTs9yIU6a6O_2ggx5QY2V3EuuDhTBQRi7QEnkZdawsbYv5CSohRvYSm1D794YhkN2OO2PkfS1kbA1bltiI69eiy46v593PY2Wq1sdWU_roGqTqeR1RXi5nWNBhnc3JnzlC7l_UdrMFykzEPornHo1xFJDCRAraBvg6KhcvASvIH07lDH6hOo2pm4SZBFeQ6_4hjm_UumvNlHW6_YAxr0u2QiY-PVtYLPdh3be7LcReMKUhp_rx6R59h6CL3F-5pUbINeWvh2RB5kV6QU-VwZ16rjF-xkrSnJxbfWR8c-8TxKwH36zhVDtw_mVC7O3F31Cs3Mj9Xfx_ZVEk6ms3XOqkpeNRIDaWXqg4hHb9S_W-pqo2X_7awNE5ZwbYA1PLcrPjBDRmU6obcqcLhPS_Y-ULj1BZqjssu1amzCZ1R5XbI3SIVkYUy0KemDM4HXvTZwnuE6-E66j1xVjc6d9b7-TIgLBjh3XGs3-jHxuEOSg1VImoPSf3kwd47-vUv1V8KRn15OqqgJiD5h4oocRrmDfUvsp0kj6iYL4qYXLqb2TBcLEddB0Hj96S9oA2qFuiLkWgz74xJlPVf01wdSc2k=s681-no?authuser=0'
        },
        {
            'name': 'skittles',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/tEyhnDoE1Oqi-CZia5ua2qstgS5QSEOfEl9hUr6bMhrZY5Piz6mm1zLy4tGepET4jfXZzg5b22uNz_cYPZWtF9gbWmU4K5dcrUC7yFTDT2m3vDu9deEyngz-NgTWUB6BKZUcqTfz0c8HISw9LDCTuJYzZb8ZFQ4udHZGANwttmFkmdUj7jvMFjXobqAS3bnIodWhamPIV7cqw7QtbWwm2HkCB13VaRnsuv0TWVykmKFIqTou2OHCRlwPvozzbWbPsi_Oj67a7zSXLvUloT8l8KIf6lHw2DaEriOFcSDfzc7bgnpKh9srEYm8XnHlVsrs273qjngiyfDv98Zjnoq6s-5qOGskC7PnW4rmM5Gc2U3XP5l1WA3xsKZlUb3dmQ0ALexESRI8sBOyYYhvkA2_Opl65EAF4LYcbUY4QP1O6EcLhjaVPPsmwUPlLn25A2FRLaEI6SQ4FjcpnI_-Ilp_3RwcXTLQVKYOUm_KlZ8VGBhPfl8a76S7zeLyUWWMpidntcobcdbtZomxrsqQsvz9Vuu_s8Kio3CTpIFa2PDSyDxzOc2P6sOldQgUEz3A9bPqhhpu2AaEzjWh1rtG86rQqT8YLcuV9xhiBy_nbHxXL-n3MhQCFv3SPqFX8_82mYd0sh7EpNeDUwrNO5vV3FNfm5sxeQ7YUW0xoDTEeXGnADDKaLfzIUs-oWaXQfgeOKRcN2BDuDWrRCjjFFpzp2ooHn9hnmDZqOzJU5SYNUC1AkZvkrdhKTRVHuMLDd3HWIiKIQc0Olsga7U1y49t8byW1-WNILJuL31BIKC7vxsWlpGtDIXichfHdOgVzOv2dslL5OTfABkUHePocLURUJazKyBGZctMkkG-MWkB4xL6JNUjxTB7A3t4dtOKf3Byr_F38mllZjFsJpMLtpEiZDQjLTBL_VhxV7k1J7CmXiPJKeTJl8k=s681-no?authuser=0'
        },
        {
            'name': 'grape bubblegum',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/ZFnjY2dzlZ8eH3FOFuoMhwgcL3vWjWA4PqeuUxhd-8UXPU1KDHC5U57khgMBEb68skTWhkbF0SF3oTAnWctM-swYfctiCeS6YmQ1RokY1pOQyPwGZSIRpLWQ_JRknDgz-RYCF7RyIKo6Ou2uAaDSf0y1o0JMTlfNx4cSyateXmkMYGBxZHphmTPN9x2MU0M8wf2l3mG9qbNvh3E45tYPjRUM_pMC3lVKwbLTIOsmKEVqvky04RS4oycufmxtL8a0iJ5nlHwUD1Wuln3uTcHx8RWMq-ZjvhiVk-p8BdoNeaXGYKDpzVcHCcXQ1WXWhHV3kmTwsX87dAZxNFdONB3BIXo6SjhJz9jSKR_esva9REtqpFCb4eRlia_m8n2yR-04dDzUrlBGulSWe-CTIHJU5lyDPiwnTw8YoH-8GY0bXfoxKPFqMCAFfg6WRMXssXZEcfwvJ_bR_hZiep-85mWehn8ydZqGy1bOkRujum6Tqf3OdhdgXubh8pgQLBatE4OL0FT5gw_SSNwcynnhiV3DF2Ht54g9bsWhVXkeRoXZtVNrkIzRAQXjmw-eypIRq9MOnLNbS9qEkQzbjY7uJqzDhRNEBozcAm2ktuL9yaj1fPRN-lvuhsUyVbIyIIz1hNLLAmJo1gYPABV0X9aIkv_DBj-3XZRXcHAJVuqe53uzDl9gFuJzl8AI9xiGZYMHfqLMcgSDMQwlWL9o5VB1qDPcnBSjtMD6RAE3kkFhlsEcWoL1iQKac472c8_RHuaF8CzFchNJ1-MZx1Ava-IIfbH2QL-xkoo0aC2YD4IRuTMuAiPkibq-K1WhIEC2vya6GQvFZVO58PiQ3ZYEZHsinmGSy7DdWS79IK_ziBMZ3HnuMUQg1MQ1fI7XOtw0YN089dONIPWyFVGX0qox9r7Rfbru0Nyn_07a4C708LTy5HDmnY_rMHw=s681-no?authuser=0'
        },
        {
            'name': 'grape ice',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/rT8tMCiR4fc3V_JM-OxyPMkcu7kXPrN9BAF3FUktFqlgs_3VIgd0ziHZ_4V5SP8C16RQAYwDEKxs0b4Ide5OsejL8gk_uAXonAEyjr-jE_1i0irwMzkSLiyUFPFhsh11HqT0oEv4M_ZcGA8qORRI-RZHEOs_sEfqDmJYKp4c4b3uUJvXX3VnAK09S4MhJtjM8JyncCXuVj70g5nZ7Dry1VyesNhdp8d0X7Yy7KbFu9dHnzLDMCxGU2_H3m0vpva0TrYb-JJYWH77mtsbE_PYwFITIIqLYdC9hxwEedPf6N_GzTQSiPFSfLEty1QRSIXEaBKjhgt0zBJVI6LNzxQEoyCPqccL3CrEzpwUC_MP2312ZCcIxSKBmfJetYIWGX384dyVen2D3y2FSQVVwjI1FWdLtkPwUqdoHuqUef_VFIcFLbyBirQUkGMWaurj0x3yDODYXKkFD01Q0EVbq6592xokXk5KWOlXyBC8RQwWINSIMWRaMBGFpvtcS524bPunXc-how4ilMj1BPXzAf7Ov74x9Tk8GrKYBxivthmulxC0kQM6qp9ZpCNgpbuK6-GL5L2kg6gox0o40dXpRnowyIXtBEqZVWzo-v9HZhdlfM2Qfy02qqIdEE5BcMqaxeioUf2rt6_vSkEolnyHN-CrLQR2ZRrn_cjngb2uZ6FI4ZHsGPJ8mUyD82SQ23EAm1nL5gxIKfVbPiqxnoJ5VCKTRv0DCilcVnnhA3KgkzRaq6Zqe_1dPSWfSyvKejFnmJ3ju1VadZCXn-adSSaOu8uWLxoQxXncaDknwjo62VWyQAS-fvdwPrcr0hFTkC6H960IQQWEpKbg3R1tVnyDun0HQhQScvFAv466VQ9_Ti6lvCWhJNuzpy5GhIFF11uhRhQfb4J8vQsLiqptMEFmUtnjjZ-4gEb2Ws_SdkwGsaKckuKE4fI=s681-no?authuser=0'
        },
        {
            'name': 'sour green apple ice',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/9OtoQEMbIIs2QFt7tjJh86PNKJqRkxJks3D95qNY4K9_vZEbX3HUYoGBiHlJ71D2MxK7bLoVkUkRDgwoBMsHGgoJct_OYs2pKynObFDGpsonpb5-OvKMAky6UukBW19O3etsw7w-QYYWn_x05DJdBNfS_YDtMnkId1bqSM-I14xZRFlG1j92D-EkR2bPwheI8PCDSbK8nqfkQBbIdkc92-IPFi7nqGaUZuwcOGTEAs2mgwb235CZXJmntL_PQeUutgFZfktcHvYbjMYz7iljCQiTGAukSBngUeJ54bU6_zUI79UaaiivmaW2i-gPPDn811Mi0GK9TWwRBfI7jSHFvXwpMyUfVzUHklNpNNKl8LDGm2oHkAiynugB1z3QmxwAIiSnh9PIw2zcddtGW32E9LJ3_4Mp91l7ICH04hxXg9uD3hGq9DskgMu2_Zo4gNi8IXufzcNAie-5ctj7vUydAo-fktj5O4f0Y6_j9OkJcHvDPztUY0FBdeFUVMlM3TXW53QLhLvJuhquPNHStOQQ_62rQWijNPxFtLJvyCgp-aXb1VYTRVXeazAV6jjh_d0sOxtMGGXKX_cJvbYP-UhV7MMMJol6qADzktucIWZLZmDhqLQS_GW3mWBDtz5LBGxvpcIToTfGiEh1JkJpsgtT7l0m3PGDPBfkU3FIH1SAak5lWmo8ltV1_7yQGJti8YCJfJhfSN3jG14pK15cfBlQl4jgk4fwwlLkkCrgqbz4mSVAWBsWySnZjNelkBA0x-bqKKNbFCzRkSLh63cA4MhJ-HeXZfknvZzFsdfouUjGPYGMelXgUjEQYMFnB9pI7rmpyxjhfsow057tWq7Hsozzhr4wFvwAbjvkTmWLfw8cYkkvDYwmMKHcHa5lkB5YIUAmNzDMDIhpqtTIH-KxwYfzCmvQZgCqBJccGhEyKsmx3-0=s695-no?authuser=0'
        },
        {
            'name': 'pineapple mango',
            'puff': 4000,
            'img':'https://lh3.googleusercontent.com/Dsx2g7U_00Xo7geyMgY0DRsF6a3uLE3ilLBVHhh-KjyhcTpmy7Zptk0secePolApPXDjir-N0PhJ7GEY3C_djZIgnMbtHKCVdCX2K8BmVjOLY-SPuEidLxPD4fI2j2npabLwS7BuhORG6Y0GaKbayxPsn8uvR0SQfZ_QuliI0BKwmkrIQQD1CH5_w6mTiNIStiZlMRu7ydsWV4kvy59s4tKPjKeogfqWba-EI5KcqFRSOn6z7pWKR5H18sO5-c_t9WzqNTaiYy8EdY7d9qiruZBgr1NheUwNN4I9K1tQjjyzJxCNTM77mWqGVcPClgFAjRPu5rGIFXHPx5TXH3cUXioxf3kFOjTlHCY3-K0MitFwfadSRTwxly6B5p0Zm9LOMpxlHkYbVtK6Vl4B9z8m3imDUi-no9ZaVzcFcQzo5pqs6ipUn4fPZRYEV31JT9XPDoBzfJe3gL2mp-4FsQwBHYI_AeHRCgO6aYilpAj5tjVxostIL5EYaggSifCOuDyaoScAkAAF5f-IVJjhDCz7LumEtOqKXv8pB1DX2Zu0UiyWPkbdRY29uM12xmlaKsDjoOQc2F0Srux_lepi5SBsWqFlBiyHquCmIIXyHi_Ylf8hi1W9Y3sbsNXs-xRJCBeub_UY8bIwZFy-ta--m1f5ctiKLU1ciNdJIr5a2n1WUI7qzf1ut_yVERcbVT_Lxw8oDLcdQkXKKbGXH9Z2AxTGJnFLJyWNElnKSnU9zLAeAzySnRgdWYQ17i-7sJIPOsSklUA4Ie9o0JZxWRVspf4kH62B2hHgOUe2ZzDuvHZaO_XWTzt2jf_RBSL3tm7BZfP3W44CPETQcx4CiJcJDQsHnd241T-ub7PoOeBUtp4N6_Lr4yO7DK-M_AwNh7gLrp1RsY-UaX3FXxIOyfai_un_AD-rQsAt3Sj0YgHLGK2u_J0=s695-no?authuser=0'
        },
    ],
    'seis':[
        {
            'name': 'lemon mint',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/ORHFKzXEb5LPkEDmPkPoyqHMM2UlDjAeHdjrbdjk0bV5hwSQ9BbM_0vEBghIxbLLfH5JD2kMSqjpM76kEnygFjDVIAe_YcmP3X8CO_7zObb64iT5fChVFZgN6rrNB1_979X_OTp9hVhAthmaMLe0FakEnvm1zJT-VDrSUajgM9i_jSt4P1WIiuECEmsDipMthL-9joqsNl-bfLcUX4ecOyMRXZBv1CfRwo5lLcskBrB86nF_fwYsUQ08diFNFUkiBht7E44u5g7lLHLMiOqgHkghH3XLWIdmyE2j_a-pTZ-snsYbRyTJn730gVJux8fwLozbpKE3ehkM1cvEThObriDTVNZjvnIu2ohMSKs8uLo44Zo55JctxTnP-oCnTQPYPtEQL_QarDD5StFpmBH-pQ-mnxs_bdPlbtINNFgj3KuKklZ3s9lnG3Tv0bz0nQCFdSMZ6G2QGwKvy775_otmVCOL4i-VkXDQchd9pXFSXR0GNHHtpn-st12Rbkp-jSD6N3QEDAPnlOU4g6wrqW6x-HKQrl5U8Bv1CSTcwKv4sLyGYhKNyrK5O1eJcrFw4mgHd5uA7iyrVxQLivqE8y4YocHPWRFx3hezzJThWDa48YERszL9rqE3w8EffwxtdjqyKFkD72uUK4O3RgiewmwvgUUVjppR-VcoGaAcxR32B6EJxaiCh-g-5ysDTJu-bJhbBefL_qHL4o7H6ukkesWTWxdvtqSbmvRt3hM1xNXPhMP0XeoIYdg2N9eD3LHCMudfzkycXdIhTlPzv_rSTRseRNnPaPs2Lpm6dRNxJOgRx7hiGhSwXPVf53nnhoKNuJEx3inBis87gVzEAskfg2KPA6Nu-J35fN6VC0eDxAvhE1z2kRYsngdk60uuSdihx73y8y_lkw8paK6-HuY6m1ig_asmsl7rHULBpEEpIlB5y8MMJAo=s681-no?authuser=0'
        },
        {
            'name': 'cool mint',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/8mqdg8CyKM5eJg-I-7ttI2V_sonEZqlAOW_bQG0ZvvNGW7EqeleCWPeS8WbWlA5EckmZ97XhTwCuR_U_Ov-9xTrShxu9dIZNUjXEo8y2a2olbq_MTPl2Gp7ztQl9xQqhDaR9_p2DKF8fhq4oT4ONaHWPO61G_Agsy-7foYb41oel7kOkCqZ33tl2kwG1JhnatOkpY_nWTu6F74MhWyvhtVnhtEtvqeZ62M452E7mOmTrz3HdepxK-F1KchF22ucWh3V2huzTBwUhFgulgqXcdOifvQMjQr_W0XaP5dtoMwp_7y31eraa6G60BWJq0f5jEMM0DXbyu0o-kLjLTat_EQ5GwzJwZ9VllgBamkWBzn5JwlNREbeaE3oOcWB63y2kh1AegUpmus-i2zM6wF7Z6ZCEN071PJ4prcXI2o3w4XlIe17CyIHy5w45JTOsUEFfKZ8QHmTK0ffA-LTRFDPbhTJKekShnNARErMOo4ydmteUc0_ifKJZs0B7fXFbW9O8eJqkubsvIB4dbwdpgKDa_GVcjpIGj4mBpZSei2Ia3xNnLlV59DqdJSliv_mrSJYLxR1XzUAOKEJPfiWBKPObqLiECgeMjLPYCUDNy05BV1bj6gt7LYTpLY7-J9g6TjOBuTy8UXuQnRNZLvUI3Is3xTOHvJfjHKKY1Y3uOcnhsC3wh3xrCckb5lctNxAYAyEO4f3PLFczGHWUiZ6I5rnrxTWCllBwfdDii74wJ7rij85PS2Y8m_wVH-QgVRLxMe76vcM-T5cakLOuF4sN5KjcvpqBfbrWF-Qv2z2o0q0kJwDAgWTh58do8P31fOFEn_CAgtq-sBBP_A0z_dScAnv4sP78fG0qdES8thom6h2jbaREZ1VSUJ1E73EAeydqWXfYEcQ1X8oofzeFFQekquH11lSDr2OsDtLwyf9Mh7lBy6xYwPU=s681-no?authuser=0'
        },
        {
            'name': 'strawberry kiwi ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/Hfm8j9GPj7FcYmVBEWLnBGl0SaCDRwiGRPYxf3eAE7Wew1ki6PXzFGab9GV78CHur98K1PSoer0VdO8-dOMKzSI3ASCPKNachHn60sAuHyVf6BoDI2gzxFjk_FVBTDhFnMArFFmjpN9xjwdZP6jV536rMFxT6TGFS8Z64x1eAh_Y6kF1Itm8hIGJYVKEpJEFx2BvSld81clhNwsGf1fHjrAyDJRhB8FjbfYxbg1ArqtUQO7Ise5jMvdjRbif0LEI3z9dk-rAJKXMQXiWhIAnaJUPAokBLEM8C_TCB7MvqJw5569AneGbO8YGP9s_LaYYB4CETu8PuWdd_hBBoxJ2i-jFIpnD-koFZlqloM5fahyZ8hP-0VV-HVG6gNSwLBNl7A5pQJdE8qttR58_T0OzM_ErUBoeoiPSKTqDRpFq0WTNotfxpi1NpM0eB4LeSfyAtXfMpH-PmvLsCO8XQ3xQjehYJS16q7ZBTqQ7QuugX21_TIGVpq2LH3vmy4bV7jLoQTlgzj_E4ZC9IsQaqPAAb_TN8GzgC1UW3iEupAzASSaMmSXIZUsotv9IkZv_il2QyGFipuduSgYhSwAEa8JGrEEzD7754-jEK1YL5B-yVwS_5XN9pS0ZQlmwlM-lt-YXFZNo65Te6yYvjl_UNCxXUEuUITjWRJRpqY4-T65L4NQmW6tnEpDQsTj9A4Gi_fAGGes1crT1zK9HC5OnMY1jfE4_lQB1AppSUMrWToIFJsSv6SRSk_ALPYnhBPrDShnH7NkEoyE3XLc1BpVZJfTkAaREUNQV-LL40ahBOcDky3bXAOu4gjRIveGPS3iFQdH2OSWLpXwNpek4M7GFrUJyWtuIGkA04CbXOKI2k0FYV1TJz-4gi8UQsXIXAXjf0JJe70HWxV0tZhDJ-8VI1TOS38_WblBdV5bh613j41eoFq0SS-M=s681-no?authuser=0'
        },
        {
            'name': 'cherry ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/OzAUbnvmq-RGTZMC6G6riD_LV7pBfyGZv0WqykXiAjBpIrkQKq7NLxjW2skia7hzkJCFmAAerWc3gRAod8X9O4v5y0GBPaWJWgH1jL11PGYfBLRr-0YsFAfeXjMgkEEvlz3QoT4fOr2icjj9e4nPT9EJ91Y0FR7e8S-h-alnPXAvk_TsogWyLce1P7ZOiGZAzV45YNjV7gaxGtHITg_TSF1ObaxIS7Z4Rar-agwdBfJWsgpbdJGV3LC1XZmnGxsVX_PU6SfnK9Egvym_dZofGhjIZvDV7lv_TdMJTcq6g19JQ1UEDFlM5QukDjQEbe2Twj2HUKJONvNscJoXK7vwBt0zu5QZynlp4u9pY29FEC4N5Zgdv45YYIS-cB5yqRltqhKmHbkbP74T4p72Yf8woM4oBS2JB7pYFr9gU4mhmGqCMRLiRHPnw5Z1z6PPljrnnV9UZNiQ3RFOEGzlhDahoYXdeo2sOUjy00cNgIvQUmRL-IRg36C65Drxy2fs7IWMwRmJLTG5hYNrI8kBhNbcmUiOqmDJzKrpSKPB0aGH6xkU91epsF0aDADuOjzPaXyw2jfcLqHkgbhWyROkYQ_MnVnU7jywSAXVE4uZtOUtedhWoAjQ97VpEpeb8Mty-Bjqef_Uyf9BcVs2yyJPWFWgGWu5Qkm7y2C8VpNFckbFLTNbrtJnDLwpGqWpj51NxtK0BrMz61USJFllH1S5WRc8K9E5kkFKgMLKO48AFVvEDLc0fd4vN4X_4vAsyZixtk_6KqsNtCnysTb1D6DxqYcrU9iHMxl8OdnVmkBos9KWy4iMhTFkME9qtZGMgzGan_GpIpRAjG7O9IKzDxZMwv52npULFb1ZSWoF5cryTk7rnDZKGAFZImvukHPfXGXLTLdnolRcKoYq9az3UfFJwqLRYnE0Ufv_5zc4yrxXPeVV4nFgOXk=s695-no?authuser=0'
        },
        {
            'name': 'strawberry ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/z65H0DmXN8_5Nih0BgummakynDx74zTZ5CuAQk-SQea4R5dBKUX-yE11z7-KP0L0-LIq0JF5_QdgTXowyrnDNnCpGu7LoJo5EZX8sehjM4I-0RDakdYkXe6lkUbJPwWl20IQuA6D4LZIFyWgZFYln2BdtC3z7W_4OO_S4ytUh0-brfyOkoRp0MYS24my84iYRk97Ke5tP44H-orJjmGtW4s1oXXFIXf7pFfYyYcEeDTj7KohVmwJ6yUT7a966w18lfVJ10kvqO0-id_VvnIF-TjO4naJuXpqRkZfZFJvvfhV4b-klxkIMmaWQXCPCWg8EqaMDVTi_FMHrL1Od1DToBMlvaQt_5zrIutyg_-Zv8omUeaF_FGDGcuaOnbTGTec7980kKD9f3UuORfLpQvnQVCJfoJRUNUZz2U5b2_Baj_bPI80JwdiRPZkaOVwF5QhMQIXWAY7ZpGVxnefihKIPFDf6qM5D-PZKd6xAIIo0GP7uOqvDMGWkh9ANL7zUXc6PV6u0I_pnH8WmqAwGbOJHNKtOQoTo_i-D9dJtXenOliae59N9r-or-O4DA6px3CdmglP7Xd8gYfk9aStqmBbagrU1ow68HHA4mP-C5FXurO7SHILykIlsOnPLpI2AcJhoh1Nb6CyAkRd0WhwPf5dUhd3DYePbl4PBWtIdGKKjuL7Kvt0wokJlIZikwMWHv8ORb90qiy0B5oG2wqOQQ4qdBAFzzDXVirFiqRKUI3USr8RBsQikn7PylDUwZZSZrI1B94W57CwXLJQDcqaeTbgpp3JniiDWhgMY1WYoDBhBHAj4QasWm-YmRZaLfQHAEM6XN7a3RDehHUUaA80lnrQBP28Qnoiv9D-yLSfN1VTEjMMWElSMzl6lF8PrtEL7hN0wgC73B-K3YoFPyD4RO51rZ7w5WfjWo2awzTyN5JrvPjq-9I=s695-no?authuser=0'
        },
        {
            'name': 'white slush',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/kt_Ak9onnOB2iG3886Y-jtf9jKADLFIV0Tvu2qNTgfor12XX7w16_uL51mjGAQWz5VLWOhMFSIOsaYN3wOhq717VHXEm9U2fVYt57G9AySGVnBGtDzf4Oi6XEnkoSBJzZjJf2UOXs99B6aSo4iurtKGoPzRbjS0btfzcQ2FEm_OYfWzECTIegItdrlY5XJgp53Dzog49dXs1agXwpRLf_DvEQ6mMVnEa4046FLisj4l154kDxJ8BWnkaSy9lTjv0RxMUlbO0y_L4zEythRlXF4wyN0nCnMIh5zgzCcyRs3QjHh1JVInb7iOd4t-LCl34gk_M-Fa42yrdCQWaf2dqbgCdkV8Ksch0lvWEtXA3FLt5klWINwIJO7_VD8eBWMqdrmRKSEmgDJYmu38xVpLDGGJkjy17_3w_fNAwB-HTWscYzAW75EzCR62h4HHc6UQt5bISrNjLraKLqG_8IP9b_xfz9Gk_DVFuNUn6Wc5ZgO_8-JcJDTxm1iANj4CPvh2hqME2qNS4AuGlv96bsYQCZuHucJEv2kQcqaP26QtWI8EhaMKK8GQIoA0JrL-q_A1hukR9F0f8MOG6yEnUDyn9kxgLpD-PSuTm1FFNW2ihNEEuxAF44qqWs6rt6U3du69Us391zPl-i3CN01QnORShIvlYxr52D02Mmb9al3SSo08Wi4Nwl5PUT-7LsZPpJIcd8x5Ng3gKr_e0dms9DzKzpOkchVS8BiYjphwSxh69ABdAye-OYcuSOXbXn1KDss2a3vtzBTY9zna87onjDej5107xjlLSMrV07nMrI1nDMSjw-ksgoMb89InDy7RwcGs0_4KPdjlGm4KvrdXkMigLHat9Q-BGpJg6X4kyaZlYDQCDC7esGwKRGRF-4e7SdbNhstSx0AdK6QojHvM3MVZsyLDQQydR7mqrGdJxZ3JQZpnQMSM=s695-no?authuser=0'
        },
        {
            'name': 'strawberry watermelon ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/rDDv2gwa9l4a6cLWmZDvMi9hUXk4tdTYbomGRVoiiMTjbDcBQSkMhhhaCALy7-mpeHP2fT9Fg6rSJ8IIKe-h5WZu6ijfEPwH8k1s0wV03G5GHozzF-DRrGWv6hArbfrV5pgIAHfBgDxKdMCkOWApwKp89T5zEPu5W6rtefYoCIMtDhmO8ORhJ-cvKxmKbt7npOLCYU8XHf7z9aeCLS_s7zIaf8TAGWInU-ZTPJle6RHkZbM7nUS8zlNXeDGvBOxgTBhSxvbPKtWXKkBWtjl5C0XHk47f4UL1T6DNwZgN0NFdcQiBC607nkEYowsYTpT5l8sh95UCu3zOmHjX664ajYlsHtbULeKtOmfTHrVwHl0yrNSll99IkPpljvaPfrQWeNXeIkCIL4f3SBOk9EM0O5jpKgkjL_ilEMOjddd52HkBuxPz1JEnO4PRkXI4Oox73n12tG75yjh1yaP-d48ES_32_C2MeRlu_4sKaW9YZDTCSqbmR7GljdD5JZfS1BnRScephgIugYGAu85uyGwrRqMTk-dePexew6_hYQoYpS-hPi2BH5DZeyiAEmY8aS4sDqTUkjwKqSIAFDlswc1HQVR9w_2pokVdYf5hkJ1MLRkoeeotP468FiDr7o64ufPAu-ncgVFJ7wyra4fdQfh3ZvRuK6OFixhKthFyZh0xZ41PqnYck_RQ0m1qY625_1PUeIdwL2KZL0_-GdP4_8jbb3ZAAkY-dQ-4TyWCHjDiyAVWeMBCHHIjKZ0D67feQECQQyqI7O8rUY9cs1FViTyCpA314E825zpZq7D1O1cJWYRzB71CERN_-G6S4aDS_EPfpA3-T3wDYoXyclJJ_an9g9gWG8OdlMt-b-AVfxAeaFfaMiXsM6QFChGJU2pRZtpcopY8DVHmlAcfrkva_LARhpTkIZDle7AVuTsxchwn5G5BFn8=s695-no?authuser=0'
        },
        {
            'name': 'watermelon candy ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/FRQoivstmE5KS_VzDHu_nZjXudHr54xzO_cZhT8I7GCMYcs9JaIyRrexxjwygE6LSnNFXOhwfE6gMGxGgJcmZW40DatH6nTiPMBBZ1H_KdB9x9EpvERERS-Ud4YzKhrSkUWTD_3i961UfbupNne6SCpJfH2MNVyXeIUTkGdwgqNiF8gtNNueIkqVxm-Dd6NPCyVQ_uISpkBavic-IpL2fErbjV6IBiBdCnv76vzmc4DbmS-XTeSjtQokBDwY4t4BDUltS1k5TH2BhgjNL-jw5brkattN3vuF8JULa4JwYDkShRWYN5poonHJCB6NVw2PiIpQGM7yZyD0UIQZDqEU23jbDl_sNBlb8_wIau9r-gA9ZA_P7PcS71joHWz8TN7w1he3uoglZt-uiUVBYiJF7YDSMbf8b2UMD1BNEWJLn3ZORkbeCYqhLdRVq3GGkV93mTO33ECbuxxTVKx335gunJZPai_XrTVIMWFL3uDKLqE8LQslqczi_7TEWPl5isOvvkSk1tgSipo1Sd4dVUYsRAp0OS2ncMUazkA5GjtL8fWYsEE-OzOExAh9Kq0gbJ-6V5l9zuc4qwcL1VGUPHqIdhkSlmqRMg5P7tRpuJRHRiPYV-jiSGWSrcZkyLWBtDMesfQfMxBFDmQKtkogoiUTZ7-J7inD9y-2ArNzoGUxthUT_biickM9v5AqDBuR0csWI4267J4_D231gjA5jwz4bEwDKgOl9bHYg0GMQgBoQsSpwWIU6G_Ts6mS4rsIKe4kbbd1TdjTafxI20Tf4PiWRhPOqk7XH8RXUmqZ-UeKxTvueZ46IZht455u5L5dmVJNMv9Y2V5ha4kokMjJqOGTLJ67fO9PXeASIL3x6KK1rBQB2HKaqoV-zcuVDnKok4Kt3jnWzWYFMs44bVpyFX1UQmiT_GkIXiKh3drSClcNMB0=s695-no?authuser=0'
        },
        {
            'name': 'strawberry kiwi ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/r0I5LvqrwTh6FmQlrgifAjGZpibpBAWDGQ6rnCeR-mExF3kdSRUKGywx0fkFITBr9rzj0svYmnUnt0qqE4Ol1rsO6L6PavUdctwe7XL47TuLvaK9_7HCDZ8dwYXEfAs5P5rv_rbjdchaO9nsRj1zimRgSM7jAXohaZ4Upmf4H6H5b4WLneF7mXAmaMqowqZYQQF-Ec8ltA2QtA_7MHFWRgEXif_iobkwgPDHF8gJZm_FHK1v3d_Nabif1niAGSvXrMeJx9QNgVhmMFkTBEmqWgknilrP-5DxEJOksapKnZPTq2UShtC_odZt7p_o06UYqiCgff_HZ23Xsul-DtzkPLP234RsWNoxcm8QCvxjrxw7uU-PFkOtrUmNEl53X6xC1dIYvi4giW2oi-9WHY2N-WJNjy_qdSbg12Q4o5Rx-uAYNxOIcwIcMSObz1FOIXWb3bsKecfx_M9dfc7zVv2_Gp_oDQNDJtBV-D6dxM8H3tkYyDG2wgC9T72tZkyjthfgI3wr-kGvz78ac5ZeTEkxnUA7Ffx4n53j62AIgJPEuSSbVVJww3eZ7ubjRtlh26X0CqJRIesPUGzEzY5GdGHpkEgfF76iR3mCmttlRbRmyxWNSDbILc7Yaw8_8bNED_kxIFMitfDUA8XIosR70f_3JQDD5lKtgzhlYexPxMGRiB0Sw3Iy7WwBqljliU6f0vOFTdJP8Fxce9TsA8zw3W8DTWATS72U62FpjW0sUpK1xcjjBwRl_urWt8XldNfXm8b3ZxV9YgwU63B_kbb68ou50QkKqAD8wMsghd3xrDrc6EyqOhriaYQc2BzyTqFdXrt9vXwvV-X_KhShWttv26KtDxXVSNnP2HtNlguijLocZAKjj8Wk0mQOmDEMIl5XP91uFomEfK2C7GbM6vxXyD6ywOBfWo9qoZCqpDxwjZjBwCfGYOI=s695-no?authuser=0'
        },
        {
            'name': 'sour green apple ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/bw4BLh_OR0boXkn4xf8upqwCrLLw6x0Nc0c7RbYz1Jr_bzZP0a7wSTmEDMAhh9O6xsJpTEIEHSGh8v9H-e-mqNuRouySVfcBNgaXYldF5TnE3UBSh5Wu7Z8CUOOHUqjF1vKODvxWpd-IayAEhNOESBwR0dcIkWVCPQB8rx0yA0WkP8JoEDkuDeMsUSET4iPzs4dyK0LXzx-HlAUfsa_NJ7ZqFxsGUwHMtK4jSmbDv9TQWGf8A8gAMcsf259q1LqyxxjrAen4bmnXUf60Na5yLYTl74F5m9F2_YQEtHDXY5OEF9WBUQVQKgUDJ4-P3YzeZBCYdZDgrePV028omcLw58kLS8pZuMNvAeW_QqU7XTEUn3ZOdroinNc2xarhLU3hPt4bRAjCGoIVzJCuXteNQIKB3e8Qp8dx1M0N4zIWbi9nSFcUG1Sj6rktiLVEJ8CrReyw2IQEi977wbaPwpZp-fqw0DvvdpEF6sVOPq6guA2vc0qA0AYbX4C5egSNyLABlFjJXyJ0b4wLPxG-dbFbwpHU_PA_9dRPyEUr4JwI_dbuKIUKlAXIEuJK6DuNBjL5b4QWGpvV7J9DzQHMAYj1QKemD4iQYmV9dO8QL-YJ-RbqQYuoMa4-jfH79uTE-EGPgOsBIhJkUycgxQc1GiVM2FnrjbYjOcMjBNFgaeEzrB_YaUd1QA3p_ymbZke0cyrdEDl7liBuXF0_YG7wCOz05DyG2wLYja2Yf0jKOqaWJ5S_QQ4BkIAU90zSxqFfwW32stChnpqUKvqnzE_Jp2Pac7gudjGiqAHIAvDLDd4mzhXqt1rb9Mz328xs7fGL_CPWaYtM0qv8FDTI5DkUfdlub-G2ckCked9kXC6aBku5bH4hAhzDs_CwOX7s1u22IJlgBboWmC050il9WaNbWPHLafcexN2HU0GSc8G1R2lY-P4IDTQ=s695-no?authuser=0'
        },
        {
            'name': 'grape ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/z65H0DmXN8_5Nih0BgummakynDx74zTZ5CuAQk-SQea4R5dBKUX-yE11z7-KP0L0-LIq0JF5_QdgTXowyrnDNnCpGu7LoJo5EZX8sehjM4I-0RDakdYkXe6lkUbJPwWl20IQuA6D4LZIFyWgZFYln2BdtC3z7W_4OO_S4ytUh0-brfyOkoRp0MYS24my84iYRk97Ke5tP44H-orJjmGtW4s1oXXFIXf7pFfYyYcEeDTj7KohVmwJ6yUT7a966w18lfVJ10kvqO0-id_VvnIF-TjO4naJuXpqRkZfZFJvvfhV4b-klxkIMmaWQXCPCWg8EqaMDVTi_FMHrL1Od1DToBMlvaQt_5zrIutyg_-Zv8omUeaF_FGDGcuaOnbTGTec7980kKD9f3UuORfLpQvnQVCJfoJRUNUZz2U5b2_Baj_bPI80JwdiRPZkaOVwF5QhMQIXWAY7ZpGVxnefihKIPFDf6qM5D-PZKd6xAIIo0GP7uOqvDMGWkh9ANL7zUXc6PV6u0I_pnH8WmqAwGbOJHNKtOQoTo_i-D9dJtXenOliae59N9r-or-O4DA6px3CdmglP7Xd8gYfk9aStqmBbagrU1ow68HHA4mP-C5FXurO7SHILykIlsOnPLpI2AcJhoh1Nb6CyAkRd0WhwPf5dUhd3DYePbl4PBWtIdGKKjuL7Kvt0wokJlIZikwMWHv8ORb90qiy0B5oG2wqOQQ4qdBAFzzDXVirFiqRKUI3USr8RBsQikn7PylDUwZZSZrI1B94W57CwXLJQDcqaeTbgpp3JniiDWhgMY1WYoDBhBHAj4QasWm-YmRZaLfQHAEM6XN7a3RDehHUUaA80lnrQBP28Qnoiv9D-yLSfN1VTEjMMWElSMzl6lF8PrtEL7hN0wgC73B-K3YoFPyD4RO51rZ7w5WfjWo2awzTyN5JrvPjq-9I=s695-no?authuser=0'
        },
        {
            'name': 'strawberry kiwi ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/OzAUbnvmq-RGTZMC6G6riD_LV7pBfyGZv0WqykXiAjBpIrkQKq7NLxjW2skia7hzkJCFmAAerWc3gRAod8X9O4v5y0GBPaWJWgH1jL11PGYfBLRr-0YsFAfeXjMgkEEvlz3QoT4fOr2icjj9e4nPT9EJ91Y0FR7e8S-h-alnPXAvk_TsogWyLce1P7ZOiGZAzV45YNjV7gaxGtHITg_TSF1ObaxIS7Z4Rar-agwdBfJWsgpbdJGV3LC1XZmnGxsVX_PU6SfnK9Egvym_dZofGhjIZvDV7lv_TdMJTcq6g19JQ1UEDFlM5QukDjQEbe2Twj2HUKJONvNscJoXK7vwBt0zu5QZynlp4u9pY29FEC4N5Zgdv45YYIS-cB5yqRltqhKmHbkbP74T4p72Yf8woM4oBS2JB7pYFr9gU4mhmGqCMRLiRHPnw5Z1z6PPljrnnV9UZNiQ3RFOEGzlhDahoYXdeo2sOUjy00cNgIvQUmRL-IRg36C65Drxy2fs7IWMwRmJLTG5hYNrI8kBhNbcmUiOqmDJzKrpSKPB0aGH6xkU91epsF0aDADuOjzPaXyw2jfcLqHkgbhWyROkYQ_MnVnU7jywSAXVE4uZtOUtedhWoAjQ97VpEpeb8Mty-Bjqef_Uyf9BcVs2yyJPWFWgGWu5Qkm7y2C8VpNFckbFLTNbrtJnDLwpGqWpj51NxtK0BrMz61USJFllH1S5WRc8K9E5kkFKgMLKO48AFVvEDLc0fd4vN4X_4vAsyZixtk_6KqsNtCnysTb1D6DxqYcrU9iHMxl8OdnVmkBos9KWy4iMhTFkME9qtZGMgzGan_GpIpRAjG7O9IKzDxZMwv52npULFb1ZSWoF5cryTk7rnDZKGAFZImvukHPfXGXLTLdnolRcKoYq9az3UfFJwqLRYnE0Ufv_5zc4yrxXPeVV4nFgOXk=s695-no?authuser=0'
        },
        {
            'name': 'blueberry ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/ZxrKj0qwD5ly-_O9Ft7XOQRjLnLMEJuTyK5RULtRFNvKXotSrZg3WfpWcj45tbBy8ynOITHsT-90nRGPjp36uzVcyoqPaKQenHn6fXzbCAGD6cd6lBMClQOyW6KPaI-5eQ3R68eWZfUPhDXf0TwNWcXHMDD4pDmQK1Yikxf8HePgfLb0s_ikLa-NKzNCqg-awsyY4ud9S-D1dAIdaiB_4ZDjzr_bDfaX2Zmgo6h1eMZmmCMJpHx9ANaQ85t2S3P2nXqvR65YLKZKOC-xnC3Y1UO6I6Aptwf7oRhhEoL1UrtRGBItrwJ8h7gyRh7b2xTxoHLSu9yZNRxU5x8S2VCvAr8jfb7KX5oDgXwqqK5mtnMa-NurrCINCupdMD441vi6bW7_2O1k0V88fP5ulIP3bLazVYDNLHfNBPLOYiogBGNdXTUSGfCIA9loM7r2c04EnUelvWkWMVd4TfHa97flcAQwM-kBdNwld3PEY1DCO0T5cLqQNRbmiC7Ob6zxBQgsck7rB1U6KOj6qAA4JJuC158-vfHTWa_F7Z7zJ52eNv3NbgpW1hzb_1wUa6GzRqElQNmSnA-SU4eK-ytJWaaoEUZycJKEVhbDBt6eAABttgScYBOT5oIa5CP29U_3sP9jeavoRyzIWJGp74btdUYU3JWO6mVSCQGWYp_EQxbBFqW3b4SBng0HpUjAJ5Dk_7-zNzAMqC3HlVpbXdNzmUPLbxpyAeTI0kemR0BqGLtkDjXfwGaJar0jcKPadG-lJAHOO5JFYtsfYv9SE-EejzZNkbkpRQvxlvUTl-oT1NhezWq2K71M8sz46bZ2GzbuxmgoV-DBsVlClSik3lAk8ZbjizYqgc3LUrNMyL_XCOrql5f-HKDDNdIGZLqrq5uGVR6tptHB5elIkx7IQ-MPPa7A-nCjiyrTSsOvkEG9KfxVfhPPoUM=s695-no?authuser=0'
        },
        {
            'name': 'skittles',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/9B0vwVmIeEZbM7GzEGvip9IUb2DZMLFppb0mMAvqnoUhq3RGZ1J-ouNSHR_PQmVgGQgM19kdwWmxMdAdmsSCFbs63DM7jHSHyz1YGJHXynCX2dzvVbU-Qm1gRlICUDWyJYXb5CQl4PC8oFRmyAWKWBdp6drv1jpP_ISsiHEX_dAeoozsvhTqLQgJHddKfmJQtiCCgCwbIaqtlsvoFAM7h0z5n6_cU_bC8BlIsJKLP-E_XUhIxoc3ewYONI6lqe5OvfYGGsB1pFe9J0u3sNJR6__xEqhhaFt4a91GVyPI7uwv_XIfZAJO2qzQmDgcJ7Vq492UJdsIa6VeXdtqzKWQbdCbKvtn3i2bxN-Yt_lj_8hZJQ9XHUPAqodmL3TC1Wi0QjZreGlzL20I1F_lqOrs6BcyCLIpu-wbZ2uAxypbEPFOri1Or44gzTv1fj5dbz4lJqj9i3k7NUyI_8dWDTcKsDAlJsJ4IBwLXiVsQsRvrIWLKwhwfW-2z52mUzqfqAvYO5_fpXL8ekcYFMbEybjtUBwVKAr6knkYrMeF2KsIL30tj11_3xXW_WbTCYcixWeQg-58NizEDoT5z_xT0x44kh43yjqkXV2dMfbdsA12rDk4HvXkPt_tV_4xrbvrmJN7NL9i1PIX5Ucqtov0OLDYsBJgUVAxfMvAkl7a1lQfmwVNhqmzclLlq2S45iRDlPCb-Yc37t1oKNc5KrpDlpcdfKOzZUBQA70ccPWd3XzE5prGUQlux3V4rEBnO8ccpVxX8qwHGhiaar7l29RuSrDbkVpFkPmswQpcO1RM3X6yq_HL-1h5tMEoaKqmgkIGVRjjMbMr7Mv5yo-dap5JJwfYMy_iB7rMGPwTirmkEhEuAPyOq03Q1xgr9k7xun7Y14t-2H8LCBKYoyhrA1WcqDlD1d-YPk8OWMWM42rlSDEYD2I=s695-no?authuser=0'
        },
        {
            'name': 'blue razz ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/9ifVBHOA5oGzMzKoAaAgImc5eFqVTZeyFbcvm00oOCmJpRinq8rbyuINSdggpsCv5N-JEbGVfpVOdgV75dbvEDnnxwR4IgTDOxeiTUymnG28X5ziCZKzg4M45u_fWTE7CkwWAmOFuAvXdZgff6V9edvIqbaySx6TSVm4TG98ZzcJL313-0xjue7O4PpnhxWVKRUMNlN-V85CfUett9UliZoNjFYkzxV56YRU4TYLpEu7IlQl98Nhrj3Z0Pqiwbl3K83K4x1dzTiDVKB2TpWJZPu2NZsb2iEQmk1qvhSUVnObpaMWEKmtJPUWFruK5Q8aJd1nzn73j92UfZSHRjTX-g05kbfDGo8hCtUx5H89JWfBJ776B4maWJSJE6OauuQ4bf0SywW24cvAydh94DioZwdSB68IZluAmDmrT9z5oxebaPosvZ_PUCY_9jpOTm8mhBXJ03yk9VLgEBTSp3JyTnZguYGzTID_NSOS9Fr8HU45OKyxHTODdm_JDKQtdJIdz659NdlMQaoQFrxN267vvI5_ehgwcl5h1ROgX3fDKC1q6RChNkKWZe6RRvPpgRmmZy3f32XDEWjL3i7kjQLyHBpWGI5qeIHXGSgXVdBY7NykSDLTMLp7-_SMttbm29HcPFCVPGQd1u9M00h5HtHNFCnaqqiPnljCHTYfnu25X-g7fgWYIRn9A0hZfCDeJFXXu5GNjV4Pndggy78fgra2dwZXRZRXOSZcDsJ8UCqPkpH8y2YOqby8QUpuWsB1kvTeKzBLdHYMjmAKG-C9SDGn-fV0kL_Go5YFa74CTSE_qEqkaH-F9e-abxg_kiQLV_NdvDaZtrnyTZnVCedjHwPcHaizkCfYYINdr1pQtLt3GdcvoaDTTnQ18_v5fx_Ln5km1Am26wNIMqMbuOudlA6Z6zSYDl_YpmahO7SEsbX66YM=s695-no?authuser=0'
        },
        {
            'name': 'strawberry kiwi ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/bXTNFa6K3CHong1kaSAWw4vCJ__ghTelcpxVZJGbPY_LdRzCAx1bw25vERLzDUYj8wB8huguGCNBwaJMeIYxCOyvfdcWnjFcZ8W6xfXRAIhgLpDnUp4DpuS1BcCzT1EQBgowYLO9wM6qis9gy7weHH8IIu3KsGeLymx_zSjkZCLG5_ygZ1FJuWd4LmazGI4sajr7madN_wKzhkFPnqCc3UW4weUs2MTic5nfD0s7652t3Rqp8w71C9NSMlwBIsqK6l7d9dSZJ-B0FdFe8EBf3-aJNcfdn50Fem1AWsx7T8UdbJN-Ahix2o3-34bL4pJsUcGELL2uw5k5MErlJaNBfEq1WCDYEpNV9MSdUU0CRcv_RLM9QYuRVhUQO_0Wbw8NbfgrBbldHh3KnqvLi_zCTfHabp6dlZlktponiphJhbHGbgBClLFRJM5bRy8SV7E2KDNXz6y6q_DkX776TiITVLTkmjPBfLEKDgTEP0ekl9d9N0D1RbHoekVeYrPW9KCz4OBN2pOzBUhWcSxgy-0qXzQ0MbkO3xlaUDtgKUY0G7LTnV9Z3fycwkQdfm89QYfWVkMm2jayDWf8HfuSFcT9bF6au0RMjPW3OfWMfi-yDaOGrG6efeFb1HHsqxuKiDOX0Av7pgbZT5oNqdhOidQB9qlljx8NA77fIt0vIXqLYJvdxSTNJCtxeZRG5C9tPpfB0gjbqZ_uPYKAmqWZuoltXgNNpqPm_NDPhJt2_MfPuRc0aYZmB_QG-6OvzzrLAaTQZzrAWRDbabgR9GAUuANboHcEkEyNcrfmq8LI90KaXHZ8g4bjjrcSOriA6vqP4I8XMFnAYdq17QrNj_FspXAzUwym5yzTG5FcdvA6CQNB675BouVMQhyvpYSykqCEIsS8-anSOFTA3qojxWjDvCg-MS9HSmE0vVVHX_8A0PBRKyU=s695-no?authuser=0'
        },
        {
            'name': 'strawberry kiwi ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/rlltvRXRGbUdwBjeqrXgTs3b9YOR0dcCvjWmIlivaXzp16pRzdlv446eutcspae_oYgiM1l0suE6FhbFPp0nN8a7avMX1t9suLrWIzGbvYC_-X18zvxzlBb6D-9-Nd5U50wR9eU4mK0rY5Cj_M0EsdaDk1cj00q9LSwyXwpaZgXgBWg2RGBycOZh2fgxr4eeXu5qhTuviRYHqx2shMyNKA2-UJax7JUai1ER3y3_79r-USNi3W_t0nIF37CO4oRVMFNlRl_lgcP4AThAn0X_3ommSJWztyP5qMgqJYKk7TmhJjbQsL4JfLs5X5rQ10NSpAg9PrlHNAdGkOZjAt3Ogs3p7u319fnaEGfRO3jQNmsXnse8XheE5FTzNbRjLRmb0up5srgUhBpGYDhko9JA1tk1W2XsQM-hllzQEJIA_2B3Wog2_IaG-pcMCQgS0YUZtO8LICgE8y4NlD1a54-bylqIfHNjjmy7LXrsLMKLncNtOPDAZSiDm1k85iiDkgl21rl4rxgbt7ivrtrCUfnms0SPQxp6iPCmQ9PFHz84NiPKkbyD4rSZRNnA1KzBQbIxrk1wEWceNQrXWY4kUNTiPK1-mkhSQjKd7RqBjlfpBLQ4J8ss4t-EuHsx2gnFAtwq4w2P6ovGCLd0TWp_HbDpnkLi-qozFiFd6wToWCnAmb5I7LATbKQSUAOjZ2T7d8kN3FoNNCNWocCRyTC93RW1n0jG5yMaPWZwUO5MDT8IbQT7YoOAeifi30hRuddidbRBVMtdVsj_lQK9CHOTaTiWiwi8elJxr9Ibgt0olmrQawUZCqjeFDdG17yzZZ6avW28FxtsA3TpPSCuxbTw9_ehrqFtOISUUKR0WLMkewLP3332p1088I16M8Sz2RMRdBMkr71KuDONXHOR8YZXz4vtcpVu5VBxv16p_IubaEuQxp0=s695-no?authuser=0'
        },
        {
            'name': 'strawberry kiwi ice',
            'puff': 6000,
            'img':'https://lh3.googleusercontent.com/ZDh18LtNIE_tDJqfJW51WpsSFo61H16Zhkhor2PaFS5E6grb1XsuHMXPqgz3ssTZh255mi8Xd1QpaIVao4PM4HFdeadGAdaEsDn6U2rTWYqQxBVaVtg6Poj3Y19gXDoz21eWXcUtusbfll-qRcbz22CU8teH0lajy1gaSZsD1Cte8Y1v0FMR3H3VRA5piQUf_crk4MPa6jVL84OrYhkwnukaxCx-KXvD27qc6ezQXLOfw4bmol_lnS9gZk6nRhZOmO98EFgPwxm2_O-yHCo8C-QJMqlU7PfgK3GhgbmqESKVDSvEhAqPqLvjAwH23DKi2CyftVWU3RqzNHPzgY3pa4EdEjFRAaOTwFnn4gRqXflJzThSRmgvXCY15HRINb1bI4IA3-L-8W9ZkIOGk79BMmj0Qbyc3ETQIzKBVi04CrTxJ18mibi_LpJhxJZSkaIeRr440bD4AnblESNlGulRY_tcyFuC7sTvJprANNgRwrmM-j6ccPDjkqMaOeEyddVYbLadDsEPg9Ddss7zU1rdS1IFs3VcmHrsKpjLkRlObIwmv8KYZrgCQsvQtob_oSOGiLfmuKi1kkthDWvUqDs7CPo7TtKYrT8upAUywA56xIjl8Yn4eEbF7gQXq2-B5KK7Gf1R4-RI-jzSOKWhu1s4rEbFdPgBgr1N37ZErnSFnuW1ImNCSJ_E5wUUmZyrh6ut_Uw0dWoLjl5DE8i-RqcYao2eNAQ2r6Qmah4eiR6PRrv-WMn5Cjne06JDwnX8PKu48m403zgsj4Wv_t9tcRV3XdB9A716rm_O3uvTSpm5IJ8urrYhGeZHumRdOwDd3X6ZntyijhpmF6G_KojypVackU-K3f80vR62fO3gWtqZVws9wef0W_2cnEPMamIIZ7dywtmSp1ooDabXZQRdox_k1VxNDHZayNDK02BPXaAT_o4=s695-no?authuser=0'
        },
    ]
}
 */