import styles from './FooterMobil.module.css';

const FooterMobil = () =>{

    const clic = (e) =>{
        window.open('https://api.whatsapp.com/send?phone=5491124042253'); 
    } 

    return(
        <>
        <div className={styles.body}>
             <section className={styles.section1}>
                <img className={styles.logo} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678380278/SMOG_Logo_OK_Mesa_de_trabajo_1_inydxe.png" alt="" />
                <p className={styles.p}>
                    SMOG, nacio en el año 2022<br/>
                    con la visión de ser el E-shop<br/>
                    con el mejor servicio del mercado,<br/>
                    enfocados en brindar productos de<br/>
                    calidad y fiabilidad 
                </p>
                <section className={styles.section3}>
                    <h3>SEGUINOS</h3>
                    <div className={styles.ii}>
                        <a href="https://www.instagram.com/smogarg/" target='_black'><img className={styles.log}  src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678383053/logoinsta_wb4exn.png" alt="" /></a>
                        <img className={styles.log} onClick={ e => clic(e)} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678387447/whatsapp_1_b8lryc.png" alt="" />
                    </div>
                    <p>INFORMACION DE CONTACTO</p>
                    <p>+54 9 11 2404 2253</p>
                    <p>smogargentina@gmail.com</p>
                    <p>15PM a 22PM LUN/SAB</p>
                </section>
            </section>
        </div>
        <p className={styles.pp}>WARNING: THIS PRODUCT CONTAINS NICOTINE. NICOTINE IS AN ADDICTIVE CHEMICAL. PROHIBID FOR SALE TO MINORS UNDER 21 YEARS OLD. </p>
        <p className={styles.yoo} > GGF | &copy; 2023</p>
        </>
    )
}

export default FooterMobil;