import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import styles from './CardsMobil.module.css';
import { useDispatch, useSelector } from "react-redux";
import { carrito } from '../../reducer/actions'
import React, { useEffect, useState } from "react";

function BasicExample2({ name, image, puff, price }) {

  const [inputn, setInput] = useState(false);

  const dispatch = useDispatch();
  const carrit = (e)=>{
    e.preventDefault();
    dispatch(carrito(e.target))
    setInput(true)
    time()
  }

  const time = setTimeout( ()=>{
    setInput(false)
  }, 4000 )

  return (
    <Card className={styles.bod} >
      {
        inputn === true ? <h1 className={styles.aviso}>Producto agregado</h1> : null
      }
      <Card.Img variant="top" src={image} />
      <Card.Body className={styles.bo}>
        <Card.Title>{name}</Card.Title>
        <Card.Text className={styles.pri}>
          $ {price}
        </Card.Text>
        <Button className={styles.but} onClick={ e =>{ carrit(e) }} value={name} name={puff} variant="primary">COMPRAR</Button>
      </Card.Body>
    </Card>
  );
}

export default BasicExample2;