import styles from './Footer.module.css'
import FooterMobil from './FooterMobil';
import { Link } from "react-router-dom";

const Footer = ()=>{

    return(
        <>
        { window.innerWidth <= 540 || window.innerHeight <= 500 ?  
            <FooterMobil/>
            :
        <>    
        <footer className={styles.footer}>
            <section className={styles.section1}>
                <img className={styles.logo} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678380278/SMOG_Logo_OK_Mesa_de_trabajo_1_inydxe.png" alt="" />
                <p className={styles.p}>
                    SMOG, nacio en el año 2022<br/>
                    con la visión de ser el E-shop<br/>
                    con el mejor servicio del mercado,<br/>
                    enfocados en brindar productos<br/>
                    de calidad y fiabilidad. 
                </p>
            </section>
            <section  className={styles.section2}>
                <a className={styles.a} href="#Home"><Link className={styles.a} to={'/home'}>HOME</Link></a>
                {/* <a className={styles.a} href="/shop">SOBRE NOSOTROS</a> */}
                <a className={styles.a} /* href="/shop" */><Link className={styles.a} to={'/shop'}>SHOP</Link></a>
                {/* <a className={styles.a} href="#beneficios"><Link className={styles.a} to={'/home'}>BENEFICIOS</Link></a> */}
                <a className={styles.a} href="/work"><Link className={styles.a} to={'/work'}>TRABAJA CON NOSOTROS</Link></a>
            </section>
            <section className={styles.section3}>
                    <h3 className={styles.seguinos}>SEGUINOS</h3>
                    <div className={styles.ii}>
                    <a href="https://www.instagram.com/smogarg/" target='_black'><img className={styles.log}  src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678383053/logoinsta_wb4exn.png" alt="" /></a>
                    <a href='https://api.whatsapp.com/send?phone=5491124042253'><img className={styles.log} src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678387447/whatsapp_1_b8lryc.png" alt="" /></a>
                    </div>
                    <div className={styles.losp}>
                        <p>INFORMACION DE CONTACTO</p>
                        <p>+54 9 11 2404 2253</p>
                        <p>smogargentina@gmail.com</p>
                        <p>15PM a 22PM LUN/SAB</p>
                    </div>
                    
            </section>
            
            
        </footer>
        <p className={styles.pp}><span className={styles.ppp}>WARNING:</span> THIS PRODUCT CONTAINS NICOTINE. NICOTINE IS AN ADDICTIVE CHEMICAL. PROHIBID FOR SALE TO MINORS UNDER 21 YEARS OLD. </p>
        <p className={styles.yoo} ><a className={styles.none} href="https://porfolio-ggf.vercel.app/" target='_blank'>GGF | &copy; 2023</a> </p>
        </>
        }
        </>
    )
        
}

export default Footer;