import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styles from '../Carousel/Carousel.module.css'




function ControlledCarousel() {
  
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  



  return (
    <div className={styles.cari} id='Home'>
      <Carousel activeIndex={index} onSelect={handleSelect} className={styles.car}>
        <Carousel.Item className={styles.one} data-bs-interval="2">
          <img
            className="d-block w-100" 
            /* src='./fotosyplacas/01.jpg' */
          />
          <Carousel.Caption>
            {/* <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>



        <Carousel.Item className={styles.two} data-bs-interval="2">
          <img
            className="d-block w-100"
            /* src="02.jpg" */
          />
          <div >
          </div> 
          <Carousel.Caption>
            {/* <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          </Carousel.Caption>
        </Carousel.Item>



        <Carousel.Item className={styles.three} data-bs-interval="2">
          <img

            className="d-block w-100"
            /* src="./fotosyplacas/09.png" */
          />
          <Carousel.Caption>
            {/* <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}


export default ControlledCarousel;