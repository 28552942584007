import './App.css';
import NavBar from './component/NavBar/NavBar'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Beneficios from './component/Beneficios/Beneficios';
import Shop from './component/Shop/Shop'
import Home1 from './component/Home1/Home1';
import Market from './component/Market/Market';
import LandingPage from './component/LandingPage/LandingPage'
import Work from './component/Work/Work';

function App() {


  return (
    <div class='a'>
      <BrowserRouter>
        <Routes>
        <Route path='/' element= {<LandingPage />} />
          <Route path='/Shop' element= {<Shop />} />
          <Route path='/Home' element= {<Home1 />} />
          <Route path='/market' element={<Market/>}/>
          <Route path='/work' element={<Work/>}/>
        </Routes>
      </BrowserRouter>
    </div> 
    
  )
}

export default App;
