import styles from './Carrito.module.css';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from "react";
import Products from '../Products/Products';
import CarritoProduct from '../CarritoProduct/CarritoProduct';
import { carritostate, formState } from '../../reducer/actions';
import  FormSubmit from './FormSubmit';
import { Link } from "react-router-dom";


const Carrito = ()=>{

    /* const [sumit, setSumit] = useState(false);  */
    let sumit = useSelector( state => state.estadoForm );
    const dispatch = useDispatch();
    let productes = useSelector( state => state.carrito );
    let tota = useSelector( state => state.total );
    console.log(productes,' esto es produtcs');
    useEffect( ()=>{
    },[productes]);

    const click = e => {
        e.preventDefault();
        dispatch(carritostate())
      }

    const submi = () =>{
        dispatch(formState())
    }  

    
    
    return(
        <div className={styles.car}>
            <div className={styles.fondo} onClick={ e => click(e) }></div>
            <div className={styles.p}>
                <h3 className={styles.cartcarrito}>CARRITO <img src="https://res.cloudinary.com/dkw8e06fo/image/upload/v1678658474/99a842dce79fec63f0cf34902cc0b74a_bdlyny.png" alt="" className={styles.spacecarrito} height={'38px'}/></h3> 
                <img src='https://img.icons8.com/ios-filled/1x/x-coordinate.png' className={styles.point} onClick={ e => click(e) }></img>
            </div>
            <div className={styles.fi}>
                {   
                    productes.length === 0 ? 
                        <div className={styles.a}>
                            <Link to={'/shop'}> <button>SHOP</button> </Link>
                        </div>
                         :

                            productes.map( e => {
                            return(
                                <CarritoProduct name={e.name} puff={e.puff} img ={e.img} cantidad={e.cantidad} price={e.price}/>
                            )       
                         })

                }
            </div>
            {
                productes.length > 0 && <button onClick={ e=> submi() } className={styles.button} data-hover="Gracias"><div>Realizar Compra</div></button>
            }
            {
                productes.length > 0 && <label className={styles.boton5}>TOTAL ${tota}</label>
            }
            {
                sumit && <FormSubmit/>
            }
        </div>
    )

} 

export default Carrito;